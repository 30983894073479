import { API } from '_utilities/API'

export const areasOfExpertisePositionsSkillsRelationService = {
    InitAreasOfExpertisePositionsSkillsRelation: async () => {
        return await API.get("api/areasofexpertisepositionsskillsrelation/init");
    },
    FilterAreasOfExpertisePositionsSkillsRelation: async data => {
        return await API.post("api/areasofexpertisepositionsskillsrelation/filter", data);
    },
    AddAreasOfExpertisePositionsSkillsRelationItem: async data => {
        return await API.post("api/areasofexpertisepositionsskillsrelation/add", data);
    },
    UpdateAreasOfExpertisePositionsSkillsRelationItem: async data => {
        return await API.put("api/areasofexpertisepositionsskillsrelation/update", data);
    },
    DeleteAreasOfExpertisePositionsSkillsRelationItem: async data => {
        return await API.delete("api/areasofexpertisepositionsskillsrelation/delete", data);
    },
    RestoreAreasOfExpertisePositionsSkillsRelationItem: async data => {
        return await API.post("api/areasofexpertisepositionsskillsrelation/restore", data);
    },
}

