import React, { Component } from 'react'
import LoadingBar from 'react-top-loading-bar';
import MaterialTable from 'material-table'
import * as config from '_utilities/config'
import { promoCodesService } from '_services'
import { myNextCompany } from "_utilities/common";

export default class PromoCodes extends Component {
  tableRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "Code", field: "code", editable: "onAdd" },
        { title: "Value", field: "value", type: 'numeric' },
      ],
      dataFilter: {},
      options: {
        ...myNextCompany.values.materialTableOptions
      }
    }
  }

  showDelete = () => {
    this.setState({
      dataFilter: {
        ...this.state.dataFilter,
        showDelete: !this.state.dataFilter.showDelete
      }
    });
    this.tableRef.current.onQueryChange();
  }

  render() {
    return (
      <div>
        <LoadingBar height={3} color='#f11946' onRef={ref => (this.LoadingBar = ref)} />

        <MaterialTable
          title="Company Email Restricted"
          tableRef={this.tableRef}
          columns={this.state.columns}
          options={this.state.options}
          onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
          data={query => new Promise((resolve, reject) => {

            this.LoadingBar.continuousStart();

            let dataFilter = myNextCompany.extensions.matchingDataFilter({
              ...this.state.dataFilter,
              ...query,
            });

            promoCodesService.FilterPromoCodes(dataFilter).then((data) => {
              this.setState({ dataFilter: dataFilter });

              resolve({
                data: data.PromoCodesFilter.Data,
                page: data.PromoCodesFilter.Page,
                totalCount: data.PromoCodesFilter.TotalRecords,
              });

              this.LoadingBar.complete();
            });
          })
          }
          onChangeRowsPerPage={page => {
            this.setState({
              options: {
                ...this.state.options,
                pageSize: page
              }
            });
          }}
          editable={{
            onRowAdd: newData => {
              return promoCodesService.AddPromoCodes(newData);
            },
            isEditable: (rowData) => !rowData.isdeleted,
            onRowUpdate: (newData, oldData) => {
              return promoCodesService.UpdatePromoCodes(newData);
            },
          }}
        />
      </div>
    )
  }
}
