import React, { Component } from "react";
import LoadingBar from "react-top-loading-bar";
import MaterialTable from "material-table";
import * as config from "_utilities/config";
import { activityAreasService } from "_services";
import ActivityAreaDetail from "./ActivityAreaDetail";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { myNextCompany } from "_utilities/common";

export default class ActivityAreas extends Component {
  tableRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "Code", field: "code", editable: "onAdd" },
        {
          title: "Favorite",
          field: "isfavourite",
          filtering: false,
          cellStyle: {
            width: "126px",
            padding: "0px 5px"
          },
          editComponent: props => (
            <div>
              <button className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit"
                onClick={(e) => {
                  var checkValue = myNextCompany.extensions.isUndefOrNull(props.value),
                    value = checkValue === false ? !props.value : checkValue;
                  props.onChange(value);
                }}
                tabIndex="0" type="button" title="Favorite">
                <span className="MuiIconButton-label">
                  <span className="material-icons MuiIcon-root" aria-hidden="true">{props.value ? "check_box" : "check_box_outline_blank"}</span>
                </span>
                <span className="MuiTouchRipple-root"></span>
              </button>
            </div>
          ),
          render: rowData =>
            <div>
              <button className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" onClick={() => this.favoriteUpdate(rowData)} tabIndex="0" type="button" title="Favorite">
                <span className="MuiIconButton-label">
                  <span className="material-icons MuiIcon-root" aria-hidden="true">{rowData.isfavourite ? "check_box" : "check_box_outline_blank"}</span>
                </span>
                <span className="MuiTouchRipple-root"></span>
              </button>
            </div>
        },
        { title: "Default Name", field: "defaultname" },
        { title: "External reference", field: "externalref", filtering: false, editable: "onUpdate", }
      ],
      detailActivityArea: null,
      modalActivityAreaDetail: false,
      dataFilter: {},
      options: {
        ...myNextCompany.values.materialTableOptions
      }
    };
  }

  favoriteUpdate(oldData) {
    var newData = {
      ...oldData,
      isfavourite: !oldData.isfavourite
    };

    return activityAreasService
      .UpdateActivityArea(newData)
      .then(res => {
        if (
          res.ResponseStatusCode ===
          config.ResponseStatusCodes.Success &&
          res.IsUpdated
        ) {
          this.tableRef.current.onQueryChange();
        }
      });
  }

  toggleModalActivityAreaDetail = () => {
    this.setState({ modalActivityAreaDetail: !this.state.modalActivityAreaDetail });
  }

  showDelete = () => {
    this.setState({
      dataFilter: {
        ...this.state.dataFilter,
        showDelete: !this.state.dataFilter.showDelete
      }
    });
    this.tableRef.current.onQueryChange();
  }

  render() {
    return (
      <div>
        <Modal size="lg" isOpen={this.state.modalActivityAreaDetail} toggle={this.toggleModalActivityAreaDetail}>
          <ModalHeader toggle={this.toggleModalActivityAreaDetail}>Activity Area Skills Relation</ModalHeader>
          <ModalBody>
            <ActivityAreaDetail detailActivityArea={this.state.detailActivityArea}></ActivityAreaDetail>
          </ModalBody>
        </Modal>

        <LoadingBar
          height={3}
          color="#f11946"
          onRef={ref => (this.LoadingBar = ref)}
        />
        <MaterialTable
          title="Activity Areas"
          tableRef={this.tableRef}
          columns={this.state.columns}
          options={this.state.options}
          onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
          data={query => new Promise((resolve, reject) => {

            this.LoadingBar.continuousStart();

            let dataFilter = myNextCompany.extensions.matchingDataFilter({
              ...this.state.dataFilter,
              ...query,
            });

            activityAreasService.FilterActivityAreas(dataFilter).then((data) => {
              this.setState({ dataFilter: dataFilter });

              resolve({
                data: data.ActivityAreaFilter.Data,
                page: data.ActivityAreaFilter.Page,
                totalCount: data.ActivityAreaFilter.TotalRecords,
              });

              this.LoadingBar.complete();
            });
          })
          }
          onChangeRowsPerPage={page => {
            this.setState({
              options: {
                ...this.state.options,
                pageSize: page
              }
            });
          }}
          actions={[
            (rowData) => {
              return {
                icon: 'restore_from_trash',
                hidden: !rowData.isdeleted,
                tooltip: 'Restore',
                onClick: (event, rowData) => {
                  return activityAreasService
                    .RestoreActivityArea(rowData)
                    .then(res => {
                      if (
                        res.ResponseStatusCode ===
                        config.ResponseStatusCodes.Success &&
                        res.IsRestored
                      ) {
                        this.tableRef.current.onQueryChange();
                      }
                    });
                }
              }
            },
            {
              icon: this.state.dataFilter.showDelete ? 'check_box' : 'check_box_outline_blank',
              tooltip: this.state.dataFilter.showDelete ? myNextCompany.values.messages.textHiddenDeletedRecords : myNextCompany.values.messages.textShowDeletedRecords,
              isFreeAction: true,
              onClick: (event) => this.showDelete()
            },
          ]}
          editable={{
            onRowAdd: newData => {
              return activityAreasService.AddActivityArea(newData);
            },
            isEditable: (rowData) => !rowData.isdeleted,
            onRowUpdate: (newData, oldData) => {
              return activityAreasService.UpdateActivityArea(newData);
            },
            isDeletable: (rowData) => !rowData.isdeleted,
            onRowDelete: oldData => {
              return activityAreasService.DeleteActivityArea(oldData);
            }
          }}
        />
      </div>
    );
  }
}
