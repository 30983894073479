import React, { Component } from 'react';
import Select from 'react-select';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { myNextCompany } from '_utilities/common';

export default class CandidatesFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidatesFilter: {
        candidateName: '',
        languages: null,
        registrationDateFrom: null,
        registrationDateTo: null,
        sectors: null,
        positions: null,
        skills: null,
      },
      isinvalidcontent: false,
      isarchived: false,
      languagesSelected: [],
      skillsSelected: [],
      positionsSelected: [],
      sectorsSelected: [],
      hireTypesSelected: [],
      statusSelected: [],
    };
  }

  onChange = e => {
    var candidatesFilter = this.state.candidatesFilter;
    candidatesFilter[e.target.name] = e.target.value;

    this.setState({
      candidatesFilter: candidatesFilter,
    });
  };

  onSubmit = e => {
    e.preventDefault();
    let dataFilter = {
      ...this.state.candidatesFilter,
      languages:
        !myNextCompany.extensions.isUndefOrNull(
          this.state.languagesSelected,
        ) && this.state.languagesSelected.length
          ? this.state.languagesSelected.map(
              language => language.label,
            )
          : null,
      skills:
        !myNextCompany.extensions.isUndefOrNull(
          this.state.skillsSelected,
        ) && this.state.skillsSelected.length
          ? this.state.skillsSelected.map(skill => skill.label)
          : null,
      positions:
        !myNextCompany.extensions.isUndefOrNull(
          this.state.positionsSelected,
        ) && this.state.positionsSelected.length
          ? this.state.positionsSelected.map(
              position => position.label,
            )
          : null,
      sectors:
        !myNextCompany.extensions.isUndefOrNull(
          this.state.sectorsSelected,
        ) && this.state.sectorsSelected.length
          ? this.state.sectorsSelected.map(sector => sector.label)
          : null,
      hireTypes:
        !myNextCompany.extensions.isUndefOrNull(
          this.state.hireTypesSelected,
        ) && this.state.hireTypesSelected.length
          ? this.state.hireTypesSelected.map(
              hireType => hireType.value,
            )
          : null,
      status:
        !myNextCompany.extensions.isUndefOrNull(
          this.state.statusSelected,
        ) && this.state.statusSelected.length
          ? this.state.statusSelected.map(status => status.value)
          : null,
      isvalidcontent: !this.state.isinvalidcontent,
      isarchived: this.state.isarchived,
    };
    this.props.filterCandidates(dataFilter);
  };

  render() {
    var languageOptions = this.props.languages.map(language => ({
      value: language.code,
      label: language.defaultname,
    }));

    var skillOptions = this.props.skills.map(skill => ({
      value: skill.code,
      label: skill.defaultname,
    }));

    var positionOptions = this.props.positions.map(position => ({
      value: position.code,
      label: position.defaultname,
    }));

    var sectorOptions = this.props.areasOfExpertise.map(
      areasOfExperty => ({
        value: areasOfExperty.code,
        label: areasOfExperty.defaultname,
      }),
    );

    var hireTypeOptions = [
      {
        value: myNextCompany.values.employee.value,
        label: myNextCompany.values.employee.label,
      },
      {
        value: myNextCompany.values.freelance.value,
        label: myNextCompany.values.freelance.label,
      },
    ];

    var statusOptions = [
      {
        value: myNextCompany.values.status.activeValue,
        label: myNextCompany.values.status.activeLabel,
      },
      {
        value: myNextCompany.values.status.disabledValue,
        label: myNextCompany.values.status.disabledLabel,
      },
    ];

    return (
      <div className='filters'>
        <form onSubmit={this.onSubmit}>
          <div className='row'>
            <div className='col-xl-11'>
              <div className='form-row align-items-center mb-2'>
                <div className='w-170px mr-2 ml-1'>
                  <input
                    type='text'
                    name='candidateName'
                    className='form-control'
                    placeholder='Candidate name'
                    value={this.state.candidatesFilter.candidateName}
                    onChange={this.onChange}
                  />
                </div>

                <Select
                  isMulti={true}
                  className='w-170px mr-2'
                  placeholder='Select languages'
                  value={this.state.languagesSelected}
                  onChange={languagesSelected => {
                    this.setState({ languagesSelected });
                  }}
                  options={languageOptions}
                />

                <DateRangePicker
                  format='yyyy/MM/dd'
                  className={['w-265px', 'mr-2']}
                  onChange={dateRange =>
                    this.setState({
                      candidatesFilter: {
                        ...this.state.candidatesFilter,
                        registrationDateFrom:
                          dateRange != null ? dateRange[0] : null,
                        registrationDateTo:
                          dateRange != null ? dateRange[1] : null,
                      },
                    })
                  }
                  value={[
                    this.state.candidatesFilter.registrationDateFrom,
                    this.state.candidatesFilter.registrationDateTo,
                  ]}
                />

                <Select
                  isMulti={true}
                  className='w-170px mr-2'
                  placeholder='Hire type'
                  value={this.state.hireTypesSelected}
                  onChange={hireTypesSelected => {
                    this.setState({ hireTypesSelected });
                  }}
                  options={hireTypeOptions}
                />

                <Select
                  isMulti={true}
                  className='w-170px mr-2'
                  placeholder='Select sector'
                  value={this.state.sectorsSelected}
                  onChange={sectorsSelected => {
                    this.setState({ sectorsSelected });
                  }}
                  options={sectorOptions}
                />

                <Select
                  isMulti={true}
                  className='w-170px mr-2'
                  placeholder='Select position'
                  value={this.state.positionsSelected}
                  onChange={positionsSelected => {
                    this.setState({ positionsSelected });
                  }}
                  options={positionOptions}
                />

                <Select
                  isMulti={true}
                  className='w-170px mr-2'
                  placeholder='Select skills'
                  value={this.state.skillsSelected}
                  onChange={skillsSelected => {
                    this.setState({ skillsSelected });
                  }}
                  options={skillOptions}
                />

                <Select
                  isMulti={true}
                  className='w-170px'
                  placeholder='Select status'
                  value={this.state.statusSelected}
                  onChange={statusSelected => {
                    this.setState({ statusSelected });
                  }}
                  options={statusOptions}
                />

                <div className='ml-5'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={this.state.isinvalidcontent}
                    onChange={() => {
                      this.setState({
                        isinvalidcontent: !this.state
                          .isinvalidcontent,
                      });
                    }}
                  />
                  <label className='form-check-label'>
                    Profanity
                  </label>
                </div>

                <div className='ml-5'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={this.state.isarchived}
                    onChange={() => {
                      this.setState({
                        isarchived: !this.state.isarchived,
                      });
                    }}
                  />
                  <label className='form-check-label'>Archived</label>
                </div>
              </div>
            </div>
            <div className='col-xl-1 col-md-6 text-right mt-1'>
              <button type='submit' className='btn btn-primary'>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
