import React from 'react'
import { Route } from 'react-router-dom'

export const MenuLink = ({
    to,
    activeOnlyWhenExact,
    children
}) => {
    return (
        <Route
            path={to}
            exact={activeOnlyWhenExact}
            children={({ match }) => { //match la doi tuong xac dinh su trung khop cua URL
                var active = match ? 'nav-item active' : 'nav-item';
                return (
                    <li className={active}>
                        {children}
                    </li>


                );
            }}
        />
    );
}