import React, { Component } from 'react'
import LoadingBar from 'react-top-loading-bar';
import MaterialTable from 'material-table'
import * as config from '_utilities/config'
import { positionsService } from '_services'
import { myNextCompany } from "_utilities/common";

export default class Positions extends Component {
  tableRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "Code", field: "code", editable: "onAdd" },
        { title: "Default Name", field: "defaultname" },
        { title: "External reference", field: "externalref", filtering: false, editable: "onUpdate" }
      ],
      dataFilter: {},
      options: {
        ...myNextCompany.values.materialTableOptions
      }
    }
  }

  showDelete = () => {
    this.setState({
      dataFilter: {
        ...this.state.dataFilter,
        showDelete: !this.state.dataFilter.showDelete
      }
    });
    this.tableRef.current.onQueryChange();
  }

  render() {
    return (
      <div>
        <LoadingBar height={3} color='#f11946' onRef={ref => (this.LoadingBar = ref)} />

        <MaterialTable
          title="Positions"
          tableRef={this.tableRef}
          columns={this.state.columns}
          options={this.state.options}
          onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
          data={query => new Promise((resolve, reject) => {

            this.LoadingBar.continuousStart();

            let dataFilter = myNextCompany.extensions.matchingDataFilter({
              ...this.state.dataFilter,
              ...query,
            });

            positionsService.FilterPositions(dataFilter).then((data) => {
              this.setState({ dataFilter: dataFilter });

              resolve({
                data: data.PositionFilter.Data,
                page: data.PositionFilter.Page,
                totalCount: data.PositionFilter.TotalRecords,
              });

              this.LoadingBar.complete();
            });
          })
          }
          onChangeRowsPerPage={page => {
            this.setState({
              options: {
                ...this.state.options,
                pageSize: page
              }
            });
          }}
          actions={[
            (rowData) => {
              return {
                icon: 'restore_from_trash',
                hidden: !rowData.isdeleted,
                tooltip: 'Restore',
                onClick: (event, rowData) => {
                  return positionsService
                    .RestorePosition(rowData)
                    .then(res => {
                      if (
                        res.ResponseStatusCode ===
                        config.ResponseStatusCodes.Success &&
                        res.IsRestored
                      ) {
                        this.tableRef.current.onQueryChange();
                      }
                    });
                }
              }
            },
            {
              icon: this.state.dataFilter.showDelete ? 'check_box' : 'check_box_outline_blank',
              tooltip: this.state.dataFilter.showDelete ? myNextCompany.values.messages.textHiddenDeletedRecords : myNextCompany.values.messages.textShowDeletedRecords,
              isFreeAction: true,
              onClick: (event) => this.showDelete()
            },
          ]}
          editable={{
            onRowAdd: newData => {
              return positionsService.AddPosition(newData);
            },
            isEditable: (rowData) => !rowData.isdeleted,
            onRowUpdate: (newData, oldData) => {
              return positionsService.UpdatePosition(newData);
            },
            isDeletable: (rowData) => !rowData.isdeleted,
            onRowDelete: oldData => {
              return positionsService.DeletePosition(oldData);
            }
          }}
        />
      </div>
    )
  }
}
