import { API } from '_utilities/API'

export const userCompaniesService = {
    InitUserCompanies: async () => {
        return await API.get("api/usercompanies/init");
    },
    FilterUserCompanies: async data => {
        return await API.post("api/usercompanies/filter", data);
    },
    FilterUserPricingPlanFreePeriods: async data => {
        return await API.post("api/usercompanies/filteruserpricingplanfreeperiods", data);
    },
    FilterUserPricingPaidPlans: async data => {
        return await API.post("api/usercompanies/filteruserpricingpaidplans", data);
    },
    UserCompanyProfile: async companyId => {
        return await API.get("api/usercompanies/profile/?companyId=" + companyId);
    },
    UserCompanyDetail: async companyId => {
        return await API.get("api/usercompanies/detail/?companyId=" + companyId);
    },
    UserCompanyUploadProfile: async data => {
        return await API.post("api/usercompanies/uploadprofile", data);
    },
    UserCompanyDeleteProfile: async data => {
        return await API.delete("api/usercompanies/deleteprofile", data);
    },
    UserCompanyUploadLogo: async data => {
        return await API.post("api/usercompanies/uploadlogo", data);
    },
    UserCompanyDeleteLogo: async data => {
        return await API.delete("api/usercompanies/deletelogo", data);
    },
    UserCompanyUploadPicture: async data => {
        return await API.post("api/usercompanies/uploadpicture", data);
    },
};

