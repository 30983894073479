import * as config from './config';
import _ from 'lodash';
export const myNextCompany = {
  values: {
    uploadedLogo: '/img/uploaded-logo.png',
    materialTableOptions: {
      filtering: true,
      debounceInterval: 500,
      pageSize: 10,
      pageSizeOptions: [10, 20, 50],
    },
    messages: {
      textShowDeletedRecords: 'Show Logical Deleted Records',
      textHiddenDeletedRecords: 'Hidden Logical Deleted Records',
    },
    employee: {
      value: 'emp',
      label: 'Employee',
    },
    freelance: {
      value: 'fre',
      label: 'Freelance',
    },
    status: {
      activeValue: 1,
      activeLabel: 'Active',
      disabledValue: 0,
      disabledLabel: 'Disabled',
    },
  },
  extensions: {
    getContractInfo: contracts => {
      let arrContractCode = !myNextCompany.extensions.isUndefOrNull(
        contracts,
      )
        ? contracts.split(',')
        : null;

      //Set Contracts Type
      let contractInfo = {
        employee:
          !myNextCompany.extensions.isUndefOrNull(arrContractCode) &&
          arrContractCode.some(
            contract =>
              myNextCompany.values.employee.value === contract,
          )
            ? myNextCompany.values.employee
            : null,
        freeblance:
          !myNextCompany.extensions.isUndefOrNull(arrContractCode) &&
          arrContractCode.some(
            contract =>
              myNextCompany.values.freelance.value === contract,
          )
            ? myNextCompany.values.freelance
            : null,
        contractsLabel: '',
      };

      //Set label contracts type
      let arrContract = [];
      if (
        !myNextCompany.extensions.isUndefOrNull(contractInfo.employee)
      ) {
        arrContract.push(contractInfo.employee.label);
      }

      if (
        !myNextCompany.extensions.isUndefOrNull(
          contractInfo.freeblance,
        )
      ) {
        arrContract.push(contractInfo.freeblance.label);
      }

      //Result data
      contractInfo = {
        ...contractInfo,
        contractsLabel: arrContract.join(', '),
      };
      return contractInfo;
    },
    arrayToObject: (array, key, name) => {
      return _.chain(array)
        .keyBy(key)
        .mapValues(name)
        .value();
    },

    // array.reduce((obj, item) => {
    //   obj[item[key]] = item[name];
    //   return obj;
    // }, {}),
    querystring: (name, url = window.location.href) => {
      name = name.replace(/[[]]/g, '\\$&');

      const regex = new RegExp(
        '[?&]' + name + '(=([^&#]*)|&|#|$)',
        'i',
      );
      const results = regex.exec(url);

      if (!results) {
        return null;
      }
      if (!results[2]) {
        return '';
      }

      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    matchingDataFilter: dataFilter => {
      return {
        ...dataFilter,
        filters: dataFilter.filters.map(item => {
          let isArrayValue = myNextCompany.extensions.isArray(
            item.value,
          );
          return {
            ...item,
            value: isArrayValue ? null : item.value,
            values: isArrayValue ? item.value : null,
          };
        }),
      };
    },
    urlImagesCompanyProfiles: picture => {
      return !myNextCompany.extensions.isUndefOrNull(picture)
        ? config.ImagesCompanyProfilesUrl + picture
        : null;
    },
    urlImagesCompanyLogos: picture => {
      return !myNextCompany.extensions.isUndefOrNull(picture)
        ? config.ImagesCompanyLogosUrl + picture
        : null;
    },
    urlImagesCompanyPictures: picture => {
      return !myNextCompany.extensions.isUndefOrNull(picture)
        ? config.ImagesCompanyPicturesUrl + picture
        : null;
    },
    urlImageCandidate: picture => {
      return !myNextCompany.extensions.isUndefOrNull(picture)
        ? config.ImagesCandidatesUrl + picture
        : null;
    },
    /**
     * Checks whether the specified value is of type String.
     */
    isStr: function(target) {
      return toString.call(target) === '[object String]';
    },
    /**
     * Checks whether the specified value is of type Number.
     */
    isNum: function(target) {
      return toString.call(target) === '[object Number]';
    },
    /**
     * Checks whether the specified value is a Boolean type.
     */
    isBool: function(target) {
      return toString.call(target) === '[object Boolean]';
    },
    /**
     * Checks whether the specified value is of type Date.
     */
    isDate: function(target) {
      return toString.call(target) === '[object Date]';
    },
    /**
     * Checks whether the specified value is of type Function.
     */
    isFunc: function(target) {
      return toString.call(target) === '[object Function]';
    },
    /**
     * Checks whether the specified value is of type Array.
     */
    isArray: function(target) {
      return toString.call(target) === '[object Array]';
    },
    /**
     * Checks whether the specified value is undefined.
     */
    isUndef: function(target) {
      return typeof target === 'undefined';
    },
    /**
     * Checks whether the specified value is null.
     */
    isNull: function(target) {
      return target === null;
    },
    /**
     * Check if the specified value is null or undefined.
     */
    isUndefOrNull: function(target) {
      return this.isUndef(target) || this.isNull(target);
    },
    /**
     * Checks whether the specified value is a number.
     */
    isNumeric: function(target) {
      return !isNaN(parseFloat(target)) && isFinite(target);
    },
    /**
     * Determines the value to return depending on whether the specified value is undefined.
     * @param {Object} target Value to judge
     * @param {Object} the value to return if def undefined
     * @return {Object} def if undefined, target otherwise
     */
  },
};
