import SideBar from 'components/SideBar';
import TopBar from 'components/TopBar';
import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import LogoutModal from './LogoutModal';
import NewAccountModal from './NewAccountModal';

class PageContent extends Component {
  render() {
    return (
      <div id='wrapper'>
        <ToastContainer />
        <SideBar />
        <div id='content-wrapper' className='d-flex flex-column'>
          <div id='content'>
            <TopBar />
            <div className='container-fluid'>
              {/* {alert.message && <div style={{ marginTop: '10px' }} className={`alert ${alert.type}`}>{alert.message}</div>} */}
              {this.props.children}
            </div>
          </div>
        </div>
        <LogoutModal />
        <NewAccountModal />
      </div>
    );
  }
}

export default PageContent;
