import { API } from '_utilities/API'

export const promoCodesService = {
    InitPromoCodes: async () => {
        return await API.get("api/PromoCodes/init");
    },
    FilterPromoCodes: async data => {
        return await API.post("api/PromoCodes/filter", data);
    },
    AddPromoCodes: async data => {
        return await API.post("api/PromoCodes/add", data);
    },
    UpdatePromoCodes: async data => {
        return await API.put("api/PromoCodes/update", data);
    },
}

