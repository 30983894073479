import { API } from '_utilities/API'

export const autoSuggestionService = {
    AreasOfExpertisePositionsExtended: async (data) => {
        return await API.post("api/AutoSuggestion/AreasOfExpertisePositionsExtended", data);
    },
    AreasOfExpertisePositionsSkillsExtended: async (data) => {
        return await API.post("api/AutoSuggestion/AreasOfExpertisePositionsSkillsExtended", data);
    },
};

