import { API } from '_utilities/API';
export const authenticateService = {
  login,
  logout
};

function login(username, password) {
  return API.login('token', username, password).then(token => {
    return token;
  });
}
function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('token');
}
