import React, { Component } from 'react'
import LoadingBar from 'react-top-loading-bar';
import MaterialTable from 'material-table'
import * as config from '_utilities/config'
import { companiesService } from '_services'
import { myNextCompany } from '_utilities/common';

export default class Companies extends Component {
  tableRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { title: "ID", field: "id", editable: "never" },
        { title: "Company Name", field: "companyname" },
        { title: "Country", field: "countrycode", lookup: {} },
        { title: "Activity Area", field: "activityarea_code", lookup: {} },
        { title: "ZIP Code", field: "zipcode" },
        { title: "City", field: "city" },
      ],
      dataFilter: {},
      options: {
        ...myNextCompany.values.materialTableOptions
      }
    }
  }
  componentDidMount() {
    this.LoadingBar.continuousStart();

    //Get Companies
    companiesService.InitCompanies().then(res => {
      this.setState({
        columns: [
          { title: "ID", field: "id", editable: "never" },
          { title: "Company Name", field: "companyname" },
          { title: "Country", field: "countrycode", lookup: myNextCompany.extensions.arrayToObject(res.Countries, "isocode", "name") },
          { title: "Activity Area", field: "activityarea_code", lookup: myNextCompany.extensions.arrayToObject(res.ActivityAreas, "code", "defaultname") },
          { title: "ZIP Code", field: "zipcode" },
          { title: "City", field: "city" },
        ],
      },
      );
      this.LoadingBar.complete()
    });
  }

  showDelete = () => {
    this.setState({
      dataFilter: {
        ...this.state.dataFilter,
        showDelete: !this.state.dataFilter.showDelete
      }
    });
    this.tableRef.current.onQueryChange();
  }

  render() {
    return (
      <div>
        <LoadingBar height={3} color='#f11946' onRef={ref => (this.LoadingBar = ref)} />
        <MaterialTable
          title="Companies"
          tableRef={this.tableRef}
          columns={this.state.columns}
          options={this.state.options}
          onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
          data={query => new Promise((resolve, reject) => {

            this.LoadingBar.continuousStart();

            let dataFilter = myNextCompany.extensions.matchingDataFilter({
              ...this.state.dataFilter,
              ...query,
            });

            companiesService.FilterCompanies(dataFilter).then((data) => {
              this.setState({ dataFilter: dataFilter });

              resolve({
                data: data.CompanyFilter.Data,
                page: data.CompanyFilter.Page,
                totalCount: data.CompanyFilter.TotalRecords,
              });

              this.LoadingBar.complete();
            });
          })
          }
          onChangeRowsPerPage={page => {
            this.setState({
              options: {
                ...this.state.options,
                pageSize: page
              }
            });
          }}
          actions={[
            (rowData) => {
              return {
                icon: 'restore_from_trash',
                hidden: !rowData.isdeleted,
                tooltip: 'Restore',
                onClick: (event, rowData) => {
                  return companiesService
                    .RestoreCompany(rowData)
                    .then(res => {
                      if (
                        res.ResponseStatusCode ===
                        config.ResponseStatusCodes.Success &&
                        res.IsRestored
                      ) {
                        this.tableRef.current.onQueryChange();
                      }
                    });
                }
              }
            },
            {
              icon: this.state.dataFilter.showDelete ? 'check_box' : 'check_box_outline_blank',
              tooltip: this.state.dataFilter.showDelete ? myNextCompany.values.messages.textHiddenDeletedRecords : myNextCompany.values.messages.textShowDeletedRecords,
              isFreeAction: true,
              onClick: (event) => this.showDelete()
            },
          ]}
          editable={{
            onRowAdd: newData => {
              return companiesService.AddCompany(newData);
            },
            isEditable: (rowData) => !rowData.isdeleted,
            onRowUpdate: (newData) => {
              return companiesService.UpdateCompany(newData);
            },
            isDeletable: (rowData) => !rowData.isdeleted,
            onRowDelete: oldData => {
              return companiesService.DeleteCompany(oldData);
            }
          }}
        />
      </div>
    )
  }
}
