import React, { Component } from 'react';
import ToggleButton from 'components/ToggleButton';
import { API } from '_utilities/API';
import { toast } from 'react-toastify';

export default class UserStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isactive: this.props.isactive,
      isarchived: this.props.isarchived,
      status: this.props.status,
    };
  }

  statusUpdate = id => {
    API.get('api/candidates/ToggleActive?id=' + id).then(res => {
      if (res.result) {
        this.setState({ isactive: !this.state.isactive });
        toast.success('Save changed.');
      } else {
        toast.error('An error has occurred!');
      }
    });
  };
  unlock = id => {
    API.get('api/candidates/Unlock?id=' + id).then(res => {
      if (res.result) {
        this.setState({ status: 'VERIFIED' });
        toast.success('Save changed.');
      } else {
        toast.error('An error has occurred!');
      }
    });
  };
  archiveOrRestore = id => {
    API.get('api/candidates/ArchiveOrRestore?id=' + id).then(res => {
      if (res.result) {
        this.setState({ isarchived: !this.state.isarchived });
        toast.success('Save changed.');
      } else {
        toast.error('An error has occurred!');
      }
    });
  };
  render() {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div>
          <h2>ACTIVE</h2>
          <ToggleButton
            value={this.state.isactive}
            onChange={() => {
              this.statusUpdate(this.props.userId);
            }}
          />
        </div>
        <div>
          <h2>STATUS</h2>
          {this.state.status === 'VERIFIED' ? (
            <p style={{ color: 'green', fontWeight: 'bold' }}>
              VERIFIED
            </p>
          ) : this.state.status === 'LOCKED' ? (
            <button
              className='btn btn-primary'
              type='button'
              onClick={() => this.unlock(this.props.userId)}
            >
              Unlock
            </button>
          ) : (
            <div>
              <button
                className='btn btn-primary'
                type='button'
                onClick={() => {
                  this.props.resendConfirmationEmail(
                    this.props.userId,
                  );
                }}
              >
                Re-send
              </button>
              {this.props.confirmationLink ? (
                <p>
                  <a
                    href={this.props.confirmationLink}
                    title={this.props.confirmationLink}
                    target='_blank'
                    style={{
                      padding: '0 10px',
                      color: '#e89d23',
                      cursor: 'pointer',
                      display: 'block',
                    }}
                  >
                    view link
                  </a>
                </p>
              ) : (
                <a
                  onClick={() => {
                    this.props.getConfirmationLink(this.props.userId);
                  }}
                  style={{
                    padding: '0 10px',
                    color: '#e89d23',
                    cursor: 'pointer',
                    display: 'block',
                  }}
                >
                  or show link
                </a>
              )}
            </div>
          )}
        </div>
        <div>
          <h2>ARCHIVE</h2>
          {this.state.isarchived ? (
            <button
              className='btn btn-outline-success c-pointer'
              type='button'
              onClick={() => this.archiveOrRestore(this.props.userId)}
            >
              Restore
            </button>
          ) : (
            <button
              className='btn btn-outline-danger c-pointer'
              type='button'
              onClick={() => this.archiveOrRestore(this.props.userId)}
            >
              Archive
            </button>
          )}
        </div>
      </div>
    );
  }
}
