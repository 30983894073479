import React, { Component } from 'react';
import Select from 'react-select';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { myNextCompany } from '_utilities/common';
import { autoSuggestionService } from '_services/autoSuggestion.service';
import { SliderSelector } from 'components/SliderSelector';

export default class JobOffersFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobOffersFilter: {
        zipcode: null,
        registrationDateFrom: null,
        registrationDateTo: null,
      },
      areasOfExpertiseSelected: [],
      positionsSelected: [],
      skillsSelected: [],
      languagesSelected: [],
      isinvalidcontent: false,
      isarchived: false,
      employeeChecked: false,
      freelanceChecked: false,
      positionOptions: [],
      skillOptions: [],
    };
  }

  sliderMonthlySalaryChange = e => {
    if (!myNextCompany.extensions.isUndefOrNull(e) && e.length) {
      var jobOffersFilter = this.state.jobOffersFilter;
      jobOffersFilter.dailyrate_from = e[0];
      jobOffersFilter.dailyrate_to = e[1];

      this.setState({
        jobOffersFilter: jobOffersFilter,
      });
    }
  };

  sliderDailyRateChange = e => {
    if (!myNextCompany.extensions.isUndefOrNull(e) && e.length) {
      var jobOffersFilter = this.state.jobOffersFilter;
      jobOffersFilter.monthlysalary_from = e[0];
      jobOffersFilter.monthlysalary_to = e[1];

      this.setState({
        jobOffersFilter: jobOffersFilter,
      });
    }
  };

  onChange = e => {
    var jobOffersFilter = this.state.jobOffersFilter;
    jobOffersFilter[e.target.name] = e.target.value;

    this.setState({
      jobOffersFilter: jobOffersFilter,
    });
  };

  onSubmit = e => {
    e.preventDefault();
    let contract = [];
    if (this.state.employeeChecked) {
      contract.push(myNextCompany.values.employee.value);
    }
    if (this.state.freelanceChecked) {
      contract.push(myNextCompany.values.freelance.value);
    }

    let dataFilter = {
      ...this.state.jobOffersFilter,
      areaofexpertise_codes:
        !myNextCompany.extensions.isUndefOrNull(
          this.state.areasOfExpertiseSelected,
        ) && this.state.areasOfExpertiseSelected.length
          ? this.state.areasOfExpertiseSelected
              .map(areasOfExperty => areasOfExperty.value)
              .join(',')
          : null,
      positions:
        !myNextCompany.extensions.isUndefOrNull(
          this.state.positionsSelected,
        ) && this.state.positionsSelected.length
          ? this.state.positionsSelected
              .map(position => position.value)
              .join(',')
          : null,
      skills:
        !myNextCompany.extensions.isUndefOrNull(
          this.state.skillsSelected,
        ) && this.state.skillsSelected.length
          ? this.state.skillsSelected
              .map(skill => skill.value)
              .join(',')
          : null,
      languages:
        !myNextCompany.extensions.isUndefOrNull(
          this.state.languagesSelected,
        ) && this.state.languagesSelected.length
          ? this.state.languagesSelected
              .map(language => language.value)
              .join(',')
          : null,
      contract:
        !myNextCompany.extensions.isUndefOrNull(contract) &&
        contract.length
          ? contract.join(',')
          : null,
      isvalidcontent: !this.state.isinvalidcontent,
      isarchived: this.state.isarchived,
    };
    this.props.filterJobOffers(dataFilter);
  };

  getAutoSuggestionAreasOfExpertisePositionsExtended = async areasOfExpertiseSelected => {
    let positionOptions = [];
    if (
      !myNextCompany.extensions.isUndefOrNull(
        areasOfExpertiseSelected,
      ) &&
      areasOfExpertiseSelected.length
    ) {
      let data = {
        AreaOfExpertiseCode: areasOfExpertiseSelected.map(
          areasOfExperty => areasOfExperty.value,
        ),
      };

      //Get data Areas Of Expertise Positions Extended
      positionOptions = await autoSuggestionService
        .AreasOfExpertisePositionsExtended(data)
        .then(res => {
          return res.AreasOfExpertisePositionsExtended.map(
            AreasOfExpertisePositionExtended => ({
              value: AreasOfExpertisePositionExtended.position_code,
              label:
                AreasOfExpertisePositionExtended.position_defaultname,
              areaofexpertise_code:
                AreasOfExpertisePositionExtended.areaofexpertise_code,
            }),
          );
        });
    }

    //Reset data Position with Areas Of Expertise change value
    let positionsSelected =
      !myNextCompany.extensions.isUndefOrNull(
        areasOfExpertiseSelected,
      ) &&
      areasOfExpertiseSelected.length &&
      !myNextCompany.extensions.isUndefOrNull(
        this.state.positionsSelected,
      ) &&
      this.state.positionsSelected.length
        ? [
            ...this.state.positionsSelected.filter(
              positionSelected => {
                return areasOfExpertiseSelected.some(
                  areasOfExperty =>
                    areasOfExperty.value ===
                    positionSelected.areaofexpertise_code,
                );
              },
            ),
          ]
        : [];

    this.setState({
      positionOptions,
      positionsSelected: positionsSelected,
    });

    //Reset data Skills with Positions change value
    this.getAutoSuggestionAreasOfExpertisePositionsSkillsExtended(
      positionsSelected,
    );
  };

  getAutoSuggestionAreasOfExpertisePositionsSkillsExtended = async positionsSelected => {
    let skillOptions = [];
    if (
      !myNextCompany.extensions.isUndefOrNull(positionsSelected) &&
      positionsSelected.length
    ) {
      let data = {
        AreaOfExpertiseCode: this.state.areasOfExpertiseSelected.map(
          areasOfExperty => areasOfExperty.value,
        ),
        PositionsCode: positionsSelected.map(
          position => position.value,
        ),
      };

      //Get data Areas Of Expertise Positions Skills Extended
      skillOptions = await autoSuggestionService
        .AreasOfExpertisePositionsSkillsExtended(data)
        .then(res => {
          return res.AreasOfExpertisePositionsSkillsExtended.map(
            AreasOfExpertisePositionsSkillExtended => ({
              value:
                AreasOfExpertisePositionsSkillExtended.skill_code,
              label:
                AreasOfExpertisePositionsSkillExtended.skill_defaultname,
              areaofexpertise_code:
                AreasOfExpertisePositionsSkillExtended.areaofexpertise_code,
              position_code:
                AreasOfExpertisePositionsSkillExtended.position_code,
            }),
          );
        });
    }

    //Reset data Skills with Positions change value
    let skillsSelected =
      !myNextCompany.extensions.isUndefOrNull(positionsSelected) &&
      positionsSelected.length &&
      !myNextCompany.extensions.isUndefOrNull(
        this.state.skillsSelected,
      ) &&
      this.state.skillsSelected.length
        ? [
            ...this.state.skillsSelected.filter(skillSelected => {
              return positionsSelected.some(
                positionSelected =>
                  positionSelected.value ===
                    skillSelected.position_code &&
                  positionSelected.areaofexpertise_code ===
                    skillSelected.areaofexpertise_code,
              );
            }),
          ]
        : [];

    this.setState({
      skillOptions,
      skillsSelected: skillsSelected,
    });
  };

  render() {
    var areasOfExpertyOptions = this.props.areasOfExpertise.map(
      areasOfExperty => ({
        value: areasOfExperty.code,
        label: areasOfExperty.defaultname,
      }),
    );

    var languageOptions = this.props.languages.map(language => ({
      value: language.code,
      label: language.defaultname,
    }));

    return (
      <div className='filters'>
        <form onSubmit={this.onSubmit}>
          <div className='row'>
            <div className='col-xl-11'>
              <div className='form-row align-items-center'>
                <Select
                  isMulti={true}
                  className='w-200px mr-2 ml-1'
                  placeholder='Area of expertise'
                  value={this.state.areasOfExpertiseSelected}
                  onChange={areasOfExpertiseSelected => {
                    this.getAutoSuggestionAreasOfExpertisePositionsExtended(
                      areasOfExpertiseSelected,
                    );
                    this.setState({ areasOfExpertiseSelected });
                  }}
                  options={areasOfExpertyOptions}
                />

                <Select
                  isMulti={true}
                  isDisabled={
                    !myNextCompany.extensions.isUndefOrNull(
                      this.state.areasOfExpertiseSelected,
                    ) && this.state.areasOfExpertiseSelected.length
                      ? false
                      : true
                  }
                  className='w-200px mr-2'
                  placeholder='Position'
                  value={this.state.positionsSelected}
                  onChange={positionsSelected => {
                    this.getAutoSuggestionAreasOfExpertisePositionsSkillsExtended(
                      positionsSelected,
                    );
                    this.setState({ positionsSelected });
                  }}
                  options={this.state.positionOptions}
                />

                <Select
                  isMulti={true}
                  isDisabled={
                    !myNextCompany.extensions.isUndefOrNull(
                      this.state.positionsSelected,
                    ) && this.state.positionsSelected.length
                      ? false
                      : true
                  }
                  className='w-200px mr-2'
                  placeholder='Select coding skills'
                  value={this.state.skillsSelected}
                  onChange={skillsSelected => {
                    this.setState({ skillsSelected });
                  }}
                  options={this.state.skillOptions}
                />

                <Select
                  isMulti={true}
                  className='w-170px mr-2'
                  placeholder='Languages'
                  value={this.state.languagesSelected}
                  onChange={languagesSelected => {
                    this.setState({ languagesSelected });
                  }}
                  options={languageOptions}
                />

                <div className='form-group w-300px mr-2'>
                  <div className='form-row'>
                    <div className='form-group col-md-4'>
                      <br />
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={this.state.employeeChecked}
                          onChange={() => {
                            this.setState({
                              employeeChecked: !this.state
                                .employeeChecked,
                            });
                          }}
                        />
                        <label className='form-check-label'>
                          Employee
                        </label>
                      </div>
                      <br />
                      <br />
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={this.state.freelanceChecked}
                          onChange={() => {
                            this.setState({
                              freelanceChecked: !this.state
                                .freelanceChecked,
                            });
                          }}
                        />
                        <label className='form-check-label'>
                          Freelance
                        </label>
                      </div>
                    </div>

                    <div className='form-group col-md-8'>
                      <div>
                        <div className='range-group text-center'>
                          <strong>Monthly salary</strong>
                          <SliderSelector
                            onChange={this.sliderDailyRateChange}
                          />
                        </div>
                      </div>
                      <br />
                      <div>
                        <div className='range-group text-center'>
                          <strong>Daily rate</strong>
                          <SliderSelector
                            onChange={this.sliderMonthlySalaryChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ verticalAlign: 'top', height: '125px' }}
                >
                  <DateRangePicker
                    format='yyyy/MM/dd'
                    className={['w-265px', 'mr-2', 'ml-4']}
                    onChange={dateRange =>
                      this.setState({
                        jobOffersFilter: {
                          ...this.state.jobOffersFilter,
                          registrationDateFrom:
                            dateRange != null ? dateRange[0] : null,
                          registrationDateTo:
                            dateRange != null ? dateRange[1] : null,
                        },
                      })
                    }
                    value={[
                      this.state.jobOffersFilter.registrationDateFrom,
                      this.state.jobOffersFilter.registrationDateTo,
                    ]}
                  />
                  <div style={{ paddingLeft: '50px' }}>
                    <div
                      style={{
                        display: 'inline-block',
                        padding: '20px',
                      }}
                    >
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={this.state.isinvalidcontent}
                        onChange={() => {
                          this.setState({
                            isinvalidcontent: !this.state
                              .isinvalidcontent,
                          });
                        }}
                      />
                      <label className='form-check-label'>
                        Profanity
                      </label>
                    </div>
                    <div
                      style={{
                        display: 'inline-block',
                        padding: '20px',
                      }}
                    >
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={this.state.isarchived}
                        onChange={() => {
                          this.setState({
                            isarchived: !this.state.isarchived,
                          });
                        }}
                      />
                      <label className='form-check-label'>
                        Archived
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-1 col-md-6 text-right mt-5'>
              <button type='submit' className='btn btn-primary'>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
