import React, { Component } from 'react';
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import wNumb from 'wnumb';
export class SliderSelector extends Component {
  onValueChanged = (values, handle, unencoded, tap, positions) => {
    this.props.onChange(unencoded);
  };
  render() {
    return (
      <Nouislider
        onChange={this.onValueChanged}
        tooltips={[true, true]}
        start={[0, 15000]}
        step={1000}
        behaviour='drag'
        connect
        range={{
          min: 0,
          max: 15000,
        }}
        format={wNumb({
          decimals: 0,
          thousand: '',
          suffix: '€',
        })}
      />
    );
  }
}