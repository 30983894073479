import { authenticateConstants } from '_constants';
import { authenticateService } from '_services';
import { history } from '_helpers';
import { alertActions } from './alert.actions';
import { myNextCompany } from '_utilities/common';
import axiosService from '_utilities/axiosService';

export const authenticateActions = {
  login,
  logout,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(
      request({
        username,
      }),
    );
    authenticateService.login(username, password).then(
      (user) => {
        if (user.access_token) {
          localStorage.setItem('token', user.access_token);
          axiosService.setHeader(
            'Authorization',
            `Bearer ${user.access_token}`,
          );
          dispatch(success(user.access_token));
          dispatch(alertActions.clear());
          const redirect = myNextCompany.extensions.querystring(
            'redirect',
          );
          const redirectPath =
            redirect === '' || redirect === null ? '/' : redirect;
          history.push(redirectPath);
        }
      },
      (error) => {
        if (error.error_description) {
          dispatch(failure(error.error_description));
          dispatch(alertActions.error(error.error_description));
        }
      },
    );
  };

  function request(user) {
    return {
      type: authenticateConstants.LOGIN_REQUEST,
      user,
    };
  }

  function success(user) {
    return {
      type: authenticateConstants.LOGIN_SUCCESS,
      user,
    };
  }

  function failure(error) {
    return {
      type: authenticateConstants.LOGIN_FAILURE,
      error,
    };
  }
}

function logout() {
  authenticateService.logout();
  window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}`;
  return {
    type: authenticateConstants.LOGOUT,
  };
}
