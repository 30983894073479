import { API } from '_utilities/API'

export const packAdvantagesService = {
    InitPackAdvantages: async () => {
        return await API.get("api/packadvantages/init");
    },
    FilterPackAdvantages: async data => {
        return await API.post("api/packadvantages/filter", data);
    },
    AddPackAdvantage: async data => {
        return await API.post("api/packadvantages/add", data);
    },
    UpdatePackAdvantage: async data => {
        return await API.put("api/packadvantages/update", data);
    },
    DeletePackAdvantage: async data => {
        return await API.delete("api/packadvantages/delete", data);
    },
    RestorePackAdvantage: async data => {
        return await API.post("api/packadvantages/restore", data);
    },
}

