import React from 'react'
import {
    Route
} from 'react-router-dom'

export const ChildMenuLink = ({ id, to, activeOnlyWhenExact, children }) => {
    return (<Route 
        path={to}
        exact={activeOnlyWhenExact}
        children={({match}) => {
            var classActive = match ? "collapse show" : "collapse";
            return (
                <div id={id} className={classActive} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        {children}
                    </div>
                </div>);
        }}
    />)

}