import React, { Component } from "react";
import { activityAreaSkillsRelationService } from "_services/tables/activityAreaSkillsRelation.service";
import MaterialTable from "material-table";
import * as config from "_utilities/config";
import { myNextCompany } from "_utilities/common";

export default class ActivityAreaSkills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "ID", field: "id", editable: "never" },
        { title: "Skill", field: "skill_code", lookup: {} },
      ],
      data: [],
      showDelete: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.activityAreaSkillsRelation !== prevProps.activityAreaSkillsRelation) {
      this.setState({
        columns: [
          { title: "ID", field: "id", editable: "never" },
          {
            title: "Skill", field: "skill_code",
            lookup: !myNextCompany.extensions.isUndefOrNull(this.props.skills) && this.props.skills.length
              ? myNextCompany.extensions.arrayToObject(this.props.skills, "code", "defaultname") : {}
          },
        ],
        data: [...this.props.activityAreaSkillsRelation]
      });
    }
  }

  filterActivityAreaSkillsRelation = (dataFilter) => {
    activityAreaSkillsRelationService.FilterActivityAreaSkillsRelation(dataFilter).then((data) => {
      this.setState({ data: data.ActivityAreaSkillsRelation });
    });
  }

  showDelete = () => {
    let dataFilter = {
      showDelete: !this.state.showDelete,
      ActivityAreaSkillRelation: {
        activityarea_code: this.props.detailActivityArea.code
      }
    }
    this.filterActivityAreaSkillsRelation(dataFilter);
    this.setState({ showDelete: dataFilter.showDelete });
  }

  render() {
    return (
      <div>
        <MaterialTable
          title={"By " + this.props.detailActivityArea.defaultname}
          columns={this.state.columns}
          data={this.state.data}
          onRowClick={(evt, selectedRow) => this.setState({ selectedRow })}
          options={{
            filtering: true
          }}
          actions={[
            (rowData) => {
              return {
                icon: 'restore_from_trash',
                hidden: !rowData.isdeleted,
                tooltip: 'Restore',
                onClick: (event, rowData) => {
                  return activityAreaSkillsRelationService
                    .RestoreActivityAreaSkillRelation(rowData)
                    .then(res => {
                      if (
                        res.ResponseStatusCode ===
                        config.ResponseStatusCodes.Success &&
                        res.IsRestored
                      ) {
                        const data = this.state.data;
                        const index = data.indexOf(rowData);
                        data[index] = res.ActivityAreaSkillRelation;
                        this.setState({ data });
                      }
                    });
                }
              }
            },
            {
              icon: this.state.showDelete ? 'check_box' : 'check_box_outline_blank',
              tooltip: this.state.showDelete ? myNextCompany.values.messages.textHiddenDeletedRecords : myNextCompany.values.messages.textShowDeletedRecords,
              isFreeAction: true,
              onClick: (event) => this.showDelete()
            },
          ]}
          editable={{
            onRowAdd: newData => {
              newData.activityarea_code = this.props.detailActivityArea.code;
              return activityAreaSkillsRelationService
                .AddActivityAreaSkillRelation(newData)
                .then(res => {
                  if (
                    res.ResponseStatusCode ===
                    config.ResponseStatusCodes.Success &&
                    res.IsAdded
                  ) {
                    const data = this.state.data;
                    data.push(res.ActivityAreaSkillRelation);
                    this.setState({ data });
                  }
                });
            },
            isEditable: (rowData) => !rowData.isdeleted,
            onRowUpdate: (newData, oldData) => {
              return activityAreaSkillsRelationService
                .UpdateActivityAreaSkillRelation(newData)
                .then(res => {
                  if (
                    res.ResponseStatusCode ===
                    config.ResponseStatusCodes.Success &&
                    res.IsUpdated
                  ) {
                    const data = this.state.data;
                    const index = data.indexOf(oldData);
                    data[index] = res.ActivityAreaSkillRelation;
                    this.setState({ data });
                  }
                });
            },
            isDeletable: (rowData) => !rowData.isdeleted,
            onRowDelete: oldData => {
              return activityAreaSkillsRelationService
                .DeleteActivityAreaSkillRelation(oldData)
                .then(res => {
                  if (
                    res.ResponseStatusCode ===
                    config.ResponseStatusCodes.Success &&
                    res.IsDeleted
                  ) {
                    let data = this.state.data;
                    const index = data.indexOf(oldData);
                    if (this.state.showDelete) {
                      data[index] = res.ActivityAreaSkillRelation;
                    } else {
                      data.splice(index, 1);
                    }
                    this.setState({ data });
                  }
                });
            }
          }}
        />
      </div>
    );
  }
}
