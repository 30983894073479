import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class UserCompanyJobOffers extends Component {
  render() {
    let userCompanyJobOffers = this.props.userCompanyJobOffers;
    return (
      <div>
        <div className='content-box'>
          <h2>Job Offers</h2>
          {userCompanyJobOffers.map((userCompanyJobOffer) => (
            <div
              className='card d-flex flex-row mb-3 active'
              key={userCompanyJobOffer.id}
            >
              <div className='pl-2 d-flex flex-grow-1 min-width-zero'>
                <div className='card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center'>
                  <span className='w-40 w-sm-100'>
                    <p className='list-item-heading mb-0 truncate'>
                      <Link
                        to={
                          '/joboffers/detail/' +
                          userCompanyJobOffer.id
                        }
                      >
                        {userCompanyJobOffer.jobtitle
                          ? userCompanyJobOffer.jobtitle
                          : 'Not defined yet'}
                      </Link>
                    </p>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
