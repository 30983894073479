import React, { Component } from 'react';
import LoadingBar from 'react-top-loading-bar';
import MaterialTable from 'material-table';
import * as config from '_utilities/config';
import { myNextCompany } from '_utilities/common';
import { userCompaniesService } from '_services';

export default class UserCompanySubscription extends Component {
  tableRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      Url: '',
      Subscriptions: [],
      dataFilter: {
        CompanyId: this.props.companyId,
      },
    };
  }

  componentDidMount() {
    this.filterUserPricingPaidPlans(this.state.dataFilter);
  }

  filterUserPricingPaidPlans = (dataFilter) => {
    userCompaniesService
      .FilterUserPricingPaidPlans(dataFilter)
      .then((data) => {
        this.setState({
          Url: data.Url,
          Subscriptions: data.subscriptions,
        });
      });
  };

  render() {
    return (
      <div>
        <div className='content-box'>
          <LoadingBar
            height={3}
            color='#f11946'
            onRef={(ref) => (this.LoadingBar = ref)}
          />
          <fieldset className='form-group'>
            <div className='row col col-md-6'>
              <legend>Subscription</legend>
              <div style={{ margin: '10px 0', width: '100%' }}>
                <a
                  className='btn btn-primary'
                  style={{ float: 'right' }}
                  href='https://dashboard.stripe.com/'
                  target='__blank'
                >
                  Open Stripe Dashboard
                </a>
              </div>
              <table className='table'>
                <tr>
                  <th>Premium plan</th>
                  <th>Validity</th>
                  <th>Payment method </th>
                  <th>Discount</th>
                  <th>Status</th>
                </tr>
                {this.state.Subscriptions.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      <i
                        className={`fas fa-check-circle ${item.status}`}
                        title={item.status}
                      ></i>
                      {item.name}
                    </td>
                    <td>{item.validity}</td>
                    <td>
                      {item.payment_method && (
                        <>
                          {item.payment_method}
                          {item.payment_collection && (
                            <>
                              <br />
                              <i>{item.payment_collection}</i>
                            </>
                          )}
                        </>
                      )}
                    </td>
                    <td>{item.discount}</td>
                    <td style={{ textAlign: 'center' }}>
                      <div className={`subscription_${item.status}`}>
                        {item.status2}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </fieldset>
        </div>
      </div>
    );
  }
}
