import React, { Component } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { myNextCompany } from '_utilities/common';
import { candidatesService } from '_services';
import NoDataFoundPage from 'pages/NoDataFound';
import * as config from '_utilities/config';
import 'video-react/dist/video-react.css'; // import css
import { Player } from 'video-react';
import { API } from '_utilities/API';
import { toast } from 'react-toastify';
import UserStatus from 'components/UserStatus';
export default class CandidateDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      candidate: null,
      candidateLanguages: [],
      candidatePositions: [],
      candidateSkills: [],
      contractInfo: {},
      candidatePicture: null,
      confirmationLink: '',
    };
  }

  onChange = (e) => {
    var candidate = this.state.candidate;
    candidate[e.target.name] = e.target.value;

    this.setState({
      candidate: candidate,
    });
  };

  componentDidMount() {
    this.LoadingBar.continuousStart();
    let candidateId = this.props.match.params.candidateId;
    if (!myNextCompany.extensions.isUndefOrNull(candidateId)) {
      candidatesService.CandidateDetail(candidateId).then((data) => {
        this.setState({
          candidate: data.Candidate,
          candidateLanguages: data.CandidateLanguages,
          candidatePositions: data.CandidatePositions,
          candidateSkills: data.CandidateSkills,
          isLoading: false,
          contractInfo: myNextCompany.extensions.getContractInfo(
            data.Candidate.user_contract,
          ),
        });
        this.LoadingBar.complete();
      });
    }
  }

  uploadPicture = () => {
    document.getElementById('upload-picture').click();
  };

  uploadVideo = () => {
    document.getElementById('upload-video').click();
  };

  fileChangedHandlerPicture = (event) => {
    const data = new FormData();
    data.append('file', event.target.files[0]);
    data.append('candidate_id', this.state.candidate.candidate_id);
    candidatesService.CandidateUploadPicture(data).then((res) => {
      if (!myNextCompany.extensions.isUndefOrNull(res.Candidate)) {
        this.setState({
          candidate: res.Candidate,
        });
      }
    });
    event.target.value = '';
  };

  fileChangedHandlerVideo = (event) => {
    const data = new FormData();
    data.append('file', event.target.files[0]);
    data.append('candidate_id', this.state.candidate.candidate_id);
    candidatesService.CandidateUploadVideo(data).then((res) => {
      if (!myNextCompany.extensions.isUndefOrNull(res.Candidate)) {
        this.setState({
          candidate: res.Candidate,
        });
      }
    });
    event.target.value = '';
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.LoadingBar.continuousStart();

    let data = {
      Candidate: {
        ...this.state.candidate,
      },
    };
    candidatesService.UpdateCandidate(data).then((data) => {
      this.setState({
        candidate: data.Candidate,
      });
      this.LoadingBar.complete();
    });
  };

  candidateDeletePicture(candidate) {
    var data = {
      CandidateId: candidate.candidate_id,
      MediaId: candidate.candidate_picture_media_id,
    };
    candidatesService.CandidateDeletePicture(data).then((res) => {
      if (
        res.ResponseStatusCode ===
          config.ResponseStatusCodes.Success &&
        res.IsDeleted
      ) {
        if (!myNextCompany.extensions.isUndefOrNull(res.Candidate)) {
          this.setState({
            candidate: res.Candidate,
          });
        }
      }
    });
  }

  candidateDeleteVideo(candidate) {
    var data = {
      CandidateId: candidate.candidate_id,
      MediaId: candidate.candidate_video_media_id,
    };
    candidatesService.CandidateDeleteVideo(data).then((res) => {
      if (
        res.ResponseStatusCode ===
          config.ResponseStatusCodes.Success &&
        res.IsDeleted
      ) {
        if (!myNextCompany.extensions.isUndefOrNull(res.Candidate)) {
          this.setState({
            candidate: res.Candidate,
          });
        }
      }
    });
  }
  resendConfirmationEmail = (id) => {
    API.get('api/candidates/ResendValidationEmail?id=' + id).then(
      (res) => {
        if (res.result) {
          toast.success('Email has sent.');
        } else {
          toast.error('An error has occurred!');
        }
      },
    );
  };
  getConfirmationLink = (id) => {
    API.get('api/candidates/GetConfirmationLink?id=' + id).then(
      (res) => {
        if (res.result) {
          this.setState({ confirmationLink: res.confirmationLink });
        } else {
          toast.error('An error has occurred!');
        }
      },
    );
  };
  render() {
    return (
      <div>
        <LoadingBar
          height={3}
          color='#f11946'
          onRef={(ref) => (this.LoadingBar = ref)}
        />
        {!this.state.isLoading ? (
          !myNextCompany.extensions.isUndefOrNull(
            this.state.candidate,
          ) ? (
            <form onSubmit={this.onSubmit}>
              <div className='content-box'>
                <div className='row'>
                  <div className='col'>
                    <a className='btn btn-primary' href='/candidates'>
                      Retour
                    </a>
                  </div>
                  <div className='col-2 text-right mb-3'>
                    <button type='submit' className='btn btn-primary'>
                      Save
                    </button>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='card bg-transparent'>
                    <div className='row'>
                      <div className='col-4'>
                        <div className='row'>
                          <div className='col'>
                            <div
                              className='c-pointer'
                              onClick={this.uploadPicture}
                            >
                              <input
                                type='file'
                                id='upload-picture'
                                className='d-none'
                                onChange={
                                  this.fileChangedHandlerPicture
                                }
                              />
                              <span
                                className='badge badge-pill badge-success position-absolute badge-top-left'
                                style={{ left: '35px' }}
                              >
                                <i className='fas fa-fw fa-upload'></i>
                              </span>
                            </div>

                            {!myNextCompany.extensions.isUndefOrNull(
                              this.state.candidate
                                .candidate_picture_media_id,
                            ) ? (
                              <div>
                                <img
                                  src={
                                    this.state.candidate
                                      .candidate_picture_url
                                  }
                                  className='img-candidate card-img rounded-circle m-3'
                                  alt='...'
                                />
                                <div
                                  className='c-pointer'
                                  onClick={() => {
                                    this.candidateDeletePicture(
                                      this.state.candidate,
                                    );
                                  }}
                                >
                                  <span
                                    className='badge badge-pill badge-danger position-absolute badge-top-left'
                                    style={{
                                      top: '50px',
                                      left: '25px',
                                    }}
                                  >
                                    <i className='fas fa-fw fa-trash'></i>
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <img
                                src={
                                  myNextCompany.values.uploadedLogo
                                }
                                className='img-candidate card-img rounded-circle m-3'
                                alt='...'
                              />
                            )}
                          </div>
                          <div className='card-body'>
                            <div className='heading'>
                              <h2>
                                {this.state.candidate.candidate_name}
                              </h2>
                              <p>
                                {
                                  this.state.candidate
                                    .candidate_address
                                }
                              </p>
                              <p>
                                {this.state.candidate.candidate_email}
                              </p>
                              <p>
                                {
                                  this.state.candidate
                                    .candidate_phonenumber
                                }
                              </p>
                              <UserStatus
                                userId={
                                  this.state.candidate.candidate_id
                                }
                                status={this.state.candidate.status}
                                isactive={
                                  this.state.candidate.isactive
                                }
                                isarchived={
                                  this.state.candidate.isarchived
                                }
                                confirmationLink={
                                  this.state.confirmationLink
                                }
                                resendConfirmationEmail={
                                  this.resendConfirmationEmail
                                }
                                getConfirmationLink={
                                  this.getConfirmationLink
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className='col-3'>
                        <Player
                          playsInline
                          src={
                            !myNextCompany.extensions.isUndefOrNull(
                              this.state.candidate
                                .candidate_video_media_id,
                            )
                              ? this.state.candidate
                                  .candidate_video_url
                              : ''
                          }
                        />

                        <div
                          className='c-pointer'
                          onClick={this.uploadVideo}
                        >
                          <input
                            type='file'
                            id='upload-video'
                            className='d-none'
                            onChange={this.fileChangedHandlerVideo}
                          />
                          <span
                            className='badge badge-pill badge-success position-absolute badge-top-right'
                            style={{ right: '3px' }}
                          >
                            <i className='fas fa-fw fa-upload'></i>
                          </span>
                        </div>

                        {!myNextCompany.extensions.isUndefOrNull(
                          this.state.candidate
                            .candidate_video_media_id,
                        ) ? (
                          <div
                            className='c-pointer'
                            onClick={() => {
                              this.candidateDeleteVideo(
                                this.state.candidate,
                              );
                            }}
                          >
                            <span
                              className='badge badge-pill badge-danger position-absolute badge-top-right'
                              style={{ right: '3px', top: '30px' }}
                            >
                              <i className='fas fa-fw fa-trash'></i>
                            </span>
                          </div>
                        ) : null}
                      </div> */}
                      <div className='col'>
                        <div className='card-body'>
                          <h2 className='card-title'>
                            <input
                              className='form-control'
                              name='candidate_title'
                              placeholder='Candidate Title'
                              value={
                                this.state.candidate.candidate_title
                              }
                              onChange={this.onChange}
                            />
                          </h2>
                          <textarea
                            className='form-control h-100px'
                            name='candidate_talentdescription'
                            placeholder='Talent Description'
                            onChange={this.onChange}
                            defaultValue={
                              this.state.candidate
                                .candidate_talentdescription
                            }
                          />
                          <div className='ml-4 mt-2'>
                            <input
                              className='form-check-input'
                              name='isvalidcontent'
                              type='checkbox'
                              checked={
                                !this.state.candidate.isvalidcontent
                              }
                              onChange={() =>
                                this.setState({
                                  candidate: {
                                    ...this.state.candidate,
                                    isvalidcontent: !this.state
                                      .candidate.isvalidcontent,
                                  },
                                })
                              }
                            />
                            <label className='form-check-label'>
                              Profanity
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-group border-group'>
                  <div className='row'>
                    {/* Candidate Positions - Skills */}
                    <div className='col-3'>
                      <div className='row'>
                        <div className='col-6'>
                          <div>
                            <h2>Positions</h2>
                          </div>
                        </div>
                        {/* <div className="col-6 ">
                          <div>
                            <h2>Skills</h2></div>
                        </div> */}
                      </div>
                      {!myNextCompany.extensions.isUndefOrNull(
                        this.state.candidatePositions,
                      ) && this.state.candidatePositions.length
                        ? this.state.candidatePositions.map(
                            (candidatePosition) => (
                              <div
                                className='row mb-4'
                                key={candidatePosition.id}
                              >
                                {/* Candidate Positions */}
                                <div className='col checkbox-group'>
                                  <div className='range-group'>
                                    <div className='col-6'>
                                      <label>
                                        <span>
                                          {
                                            candidatePosition.position_defaultname
                                          }
                                        </span>
                                      </label>
                                    </div>
                                    <div className='col-6'>
                                      <input
                                        type='range'
                                        name='range'
                                        step='1'
                                        min='1'
                                        max='5'
                                        defaultValue={
                                          candidatePosition.weight
                                        }
                                        disabled='disabled'
                                      />
                                    </div>
                                  </div>
                                </div>

                                {/* Candidate Skills by position code */}
                                {/* <div className="col-6 checkbox-group">
                              {!myNextCompany.extensions.isUndefOrNull(this.state.candidateSkills)
                                && this.state.candidateSkills.length
                                // && this.state.candidateSkills.some(candidateSkill => candidateSkill.position_code === candidatePosition.position_code) //check skills exists by position code
                                ? this.state.candidateSkills.map(candidateSkill =>
                                  // candidateSkill.position_code === candidatePosition.position_code //render skills exists by postion code
                                  //   ?
                                    <div className="range-group" key={candidateSkill.id}>
                                      <div className="col-6">
                                        <label>
                                          <span>{candidateSkill.skill_defaultname}</span>
                                        </label>
                                      </div>
                                      <div className="col-6">
                                        <input type="range" name="range" step="1" min="1" max="5" defaultValue={candidateSkill.weight} disabled="disabled" />
                                      </div>
                                    </div>
                                    // : null
                                ) : null}
                            </div> */}
                              </div>
                            ),
                          )
                        : null}
                    </div>

                    {/* Candidate Languages */}
                    <div className='col-3'>
                      <div className='row'>
                        <div className='col'>
                          <div>
                            <h2>Skills</h2>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col checkbox-group'>
                          {!myNextCompany.extensions.isUndefOrNull(
                            this.state.candidateSkills,
                          ) && this.state.candidateSkills.length
                            ? this.state.candidateSkills.map(
                                (candidateSkill) => (
                                  <div
                                    className='range-group'
                                    key={candidateSkill.id}
                                  >
                                    <div className='col-6'>
                                      <label>
                                        <span>
                                          {
                                            candidateSkill.skill_defaultname
                                          }
                                        </span>
                                      </label>
                                    </div>
                                    <div className='col-6'>
                                      <input
                                        type='range'
                                        name='range'
                                        step='1'
                                        min='1'
                                        max='5'
                                        defaultValue={
                                          candidateSkill.weight
                                        }
                                        disabled='disabled'
                                      />
                                    </div>
                                  </div>
                                ),
                              )
                            : null}
                        </div>
                      </div>
                    </div>

                    {/* Candidate Languages */}
                    <div className='col-3'>
                      <div className='row'>
                        <div className='col'>
                          <div>
                            <h2>Languages</h2>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col checkbox-group'>
                          {!myNextCompany.extensions.isUndefOrNull(
                            this.state.candidateLanguages,
                          ) && this.state.candidateLanguages.length
                            ? this.state.candidateLanguages.map(
                                (candidateLanguage) => (
                                  <div
                                    className='range-group'
                                    key={candidateLanguage.id}
                                  >
                                    <div className='col-6'>
                                      <label>
                                        <span>
                                          {
                                            candidateLanguage.language_defaultname
                                          }
                                        </span>
                                      </label>
                                    </div>
                                    <div className='col-6'>
                                      <input
                                        type='range'
                                        name='range'
                                        step='1'
                                        min='1'
                                        max='5'
                                        defaultValue={
                                          candidateLanguage.weight
                                        }
                                        disabled='disabled'
                                      />
                                    </div>
                                  </div>
                                ),
                              )
                            : null}
                        </div>
                      </div>
                    </div>

                    {/* Candidate Contract */}
                    <div className='col-3'>
                      <h2>Contract</h2>
                      <p>{this.state.contractInfo.contractsLabel}</p>
                      <div>
                        <h2>Gross salary</h2>
                        {!myNextCompany.extensions.isUndefOrNull(
                          this.state.contractInfo.employee,
                        ) &&
                        !myNextCompany.extensions.isUndefOrNull(
                          this.state.candidate
                            .candidate_monthlysalary,
                        ) &&
                        this.state.candidate.candidate_monthlysalary
                          .length ? (
                          <p>
                            {this.state.candidate
                              .candidate_monthlysalary + ' monthly'}
                          </p>
                        ) : null}

                        {!myNextCompany.extensions.isUndefOrNull(
                          this.state.contractInfo.freeblance,
                        ) &&
                        !myNextCompany.extensions.isUndefOrNull(
                          this.state.candidate.candidate_dailyrate,
                        ) &&
                        this.state.candidate.candidate_dailyrate
                          .length ? (
                          <p>
                            {this.state.candidate
                              .candidate_dailyrate + ' daily'}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <NoDataFoundPage />
          )
        ) : null}
      </div>
    );
  }
}
