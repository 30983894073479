import { API } from '_utilities/API'

export const positionsService = {
    InitPositions: async () => {
        return await API.get("api/positions/init");
    },
    FilterPositions: async data => {
        return await API.post("api/positions/filter", data);
    },
    AddPosition: async data => {
        return await API.post("api/positions/add", data);
    },
    UpdatePosition: async data => {
        return await API.put("api/positions/update", data);
    },
    DeletePosition: async data => {
        return await API.delete("api/positions/delete", data);
    },
    RestorePosition: async data => {
        return await API.post("api/positions/restore", data);
    },
}

