import React, { Component } from 'react';
export default class NoDataFoundPage extends Component {
  render() {
    return (
      <div className='text-center'>
        <p className='lead text-gray-800 mb-5'>No Data Found</p>
      </div>
    );
  }
}
