import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

import PageContent from './PageContent';
import { API } from '_utilities/API';

class AdminLayoutRoute extends Component {
  constructor(props) {
    super(props);
    API.get('api/admin');
  }
  render() {
    const { component: YourComponent, ...remainProps } = this.props;
    return (
      <Route
        {...remainProps}
        render={(routeProps) => {
          return (
            <PageContent>
              <YourComponent {...routeProps} />
            </PageContent>
          );
        }}
      />
    );
  }
}

export default AdminLayoutRoute;
