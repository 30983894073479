import React, { Component } from 'react'
import LoadingBar from 'react-top-loading-bar';
import MaterialTable from 'material-table'
import * as config from '_utilities/config'
import { languagesByCountryService } from '_services'
import { myNextCompany } from "_utilities/common";

export default class LanguagesByCountry extends Component {
  tableRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "ID", field: "id", editable: "never" },
        { title: "Country", field: "sitecountry_code", lookup: {} },
        { title: "Language", field: "language_code", lookup: {} }
      ],
      dataFilter: {},
      options: {
        ...myNextCompany.values.materialTableOptions
      }
    }
  }
  componentDidMount() {
    this.LoadingBar.continuousStart();

    languagesByCountryService.InitLanguagesByCountry().then(res => {      
      this.setState({
        columns: [
          { title: "ID", field: "id", editable: "never" },
          { title: "Country", field: "sitecountry_code", lookup: myNextCompany.extensions.arrayToObject(res.Countries, "isocode", "name") },
          { title: "Language", field: "language_code", lookup: myNextCompany.extensions.arrayToObject(res.Languages, "code", "defaultname") }
        ],
      });
      this.LoadingBar.complete()
    });
  }

  showDelete = () => {
    this.setState({
      dataFilter: {
        ...this.state.dataFilter,
        showDelete: !this.state.dataFilter.showDelete
      }
    });
    this.tableRef.current.onQueryChange();
  }

  render() {
    return (
      <div>
        <LoadingBar height={3} color='#f11946' onRef={ref => (this.LoadingBar = ref)} />

        <MaterialTable
          title="Languages by country"
          tableRef={this.tableRef}
          columns={this.state.columns}
          options={this.state.options}
          onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
          data={query => new Promise((resolve, reject) => {

            this.LoadingBar.continuousStart();

            let dataFilter = myNextCompany.extensions.matchingDataFilter({
              ...this.state.dataFilter,
              ...query,
            });

            languagesByCountryService.FilterLanguagesByCountry(dataFilter).then((data) => {
              this.setState({ dataFilter: dataFilter });

              resolve({
                data: data.LanguagesByCountryFilter.Data,
                page: data.LanguagesByCountryFilter.Page,
                totalCount: data.LanguagesByCountryFilter.TotalRecords,
              });

              this.LoadingBar.complete();
            });
          })
          }
          onChangeRowsPerPage={page => {
            this.setState({
              options: {
                ...this.state.options,
                pageSize: page
              }
            });
          }}
          actions={[
            (rowData) => {
              return {
                icon: 'restore_from_trash',
                hidden: !rowData.isdeleted,
                tooltip: 'Restore',
                onClick: (event, rowData) => {
                  return languagesByCountryService
                    .RestoreLanguageByCountry(rowData)
                    .then(res => {
                      if (
                        res.ResponseStatusCode ===
                        config.ResponseStatusCodes.Success &&
                        res.IsRestored
                      ) {
                        this.tableRef.current.onQueryChange();
                      }
                    });
                }
              }
            },
            {
              icon: this.state.dataFilter.showDelete ? 'check_box' : 'check_box_outline_blank',
              tooltip: this.state.dataFilter.showDelete ? myNextCompany.values.messages.textHiddenDeletedRecords : myNextCompany.values.messages.textShowDeletedRecords,
              isFreeAction: true,
              onClick: (event) => this.showDelete()
            },
          ]}
          editable={{
            onRowAdd: newData => {
              return languagesByCountryService.AddLanguageByCountry(newData);
            },
            isEditable: (rowData) => !rowData.isdeleted,
            onRowUpdate: (newData, oldData) => {
              return languagesByCountryService.UpdateLanguageByCountry(newData);
            },
            isDeletable: (rowData) => !rowData.isdeleted,
            onRowDelete: oldData => {
              return languagesByCountryService.DeleteLanguageByCountry(oldData);
            }
          }}
        />
      </div>
    )
  }
}
