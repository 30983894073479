import React, { Component } from 'react'
import MaterialTable from 'material-table'
import LoadingBar from 'react-top-loading-bar';
import * as config from '_utilities/config'
import { myNextCompany } from '_utilities/common'
import { areasOfExpertisePositionsRelationService } from '_services'

export default class AreasOfExpertisePositionsRelation extends Component {
  tableRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "ID", field: "id", editable: "never" },
        { title: "Favorite", field: "isfavourite", filtering: false, render: rowData => { } },
        { title: "Area Of Expertise Code", field: "areaofexpertise_code", lookup: {} },
        { title: "Position Code", field: "position_code", lookup: {} }
      ],
      dataFilter: {},
      options: {
        ...myNextCompany.values.materialTableOptions
      }
    };
  }

  favoriteUpdate(oldData) {
    var newData = {
      ...oldData,
      isfavourite: !oldData.isfavourite
    };

    return areasOfExpertisePositionsRelationService.UpdateAreasOfExpertisePositionsRelationItem(newData).then(res => {
      if (res.ResponseStatusCode === config.ResponseStatusCodes.Success && res.IsUpdated) {
        this.tableRef.current.onQueryChange();
      }
    })
  }

  componentDidMount() {
    this.LoadingBar.continuousStart();

    areasOfExpertisePositionsRelationService.InitAreasOfExpertisePositionsRelation().then(res => {
      this.setState({
        columns: [
          { title: "ID", field: "id", editable: "never" },
          {
            title: "Favorite",
            field: "isfavourite",
            filtering: false,
            cellStyle: {
              width: "126px",
              padding: "0px 5px"
            },
            editComponent: props => (
              <div>
                <button className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit"
                  onClick={(e) => {
                    var checkValue = myNextCompany.extensions.isUndefOrNull(props.value),
                      value = checkValue === false ? !props.value : checkValue;
                    props.onChange(value);
                  }}
                  tabIndex="0" type="button" title="Favorite">
                  <span className="MuiIconButton-label">
                    <span className="material-icons MuiIcon-root" aria-hidden="true">{props.value ? "check_box" : "check_box_outline_blank"}</span>
                  </span>
                  <span className="MuiTouchRipple-root"></span>
                </button>
              </div>
            ),
            render: rowData =>
              <div>
                <button className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" onClick={() => this.favoriteUpdate(rowData)} tabIndex="0" type="button" title="Favorite">
                  <span className="MuiIconButton-label">
                    <span className="material-icons MuiIcon-root" aria-hidden="true">{rowData.isfavourite ? "check_box" : "check_box_outline_blank"}</span>
                  </span>
                  <span className="MuiTouchRipple-root"></span>
                </button>
              </div>
          },
          { title: "Area Of Expertise", field: "areaofexpertise_code", lookup: myNextCompany.extensions.arrayToObject(res.AreasOfExpertise, "code", "defaultname") },
          { title: "Position", field: "position_code", lookup: myNextCompany.extensions.arrayToObject(res.Positions, "code", "defaultname") }
        ],
      });
      this.LoadingBar.complete()
    });
  }

  showDelete = () => {
    this.setState({
      dataFilter: {
        ...this.state.dataFilter,
        showDelete: !this.state.dataFilter.showDelete
      }
    });
    this.tableRef.current.onQueryChange();
  }

  render() {
    return (
      <div>
        <LoadingBar
          height={3}
          color='#f11946'
          onRef={ref => (this.LoadingBar = ref)}
        />
        <MaterialTable
          title="Areas Of Expertise Positions Relation"
          tableRef={this.tableRef}
          columns={this.state.columns}
          options={this.state.options}
          onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
          data={query => new Promise((resolve, reject) => {

            this.LoadingBar.continuousStart();

            let dataFilter = myNextCompany.extensions.matchingDataFilter({
              ...this.state.dataFilter,
              ...query,
            });

            areasOfExpertisePositionsRelationService.FilterAreasOfExpertisePositionsRelation(dataFilter).then((data) => {
              this.setState({ dataFilter: dataFilter });

              resolve({
                data: data.AreasOfExpertisePositionsRelationFilter.Data,
                page: data.AreasOfExpertisePositionsRelationFilter.Page,
                totalCount: data.AreasOfExpertisePositionsRelationFilter.TotalRecords,
              });

              this.LoadingBar.complete();
            });
          })
          }
          onChangeRowsPerPage={page => {
            this.setState({
              options: {
                ...this.state.options,
                pageSize: page
              }
            });
          }}
          actions={[
            (rowData) => {
              return {
                icon: 'restore_from_trash',
                hidden: !rowData.isdeleted,
                tooltip: 'Restore',
                onClick: (event, rowData) => {
                  return areasOfExpertisePositionsRelationService
                    .RestoreAreasOfExpertisePositionsRelationItem(rowData)
                    .then(res => {
                      if (
                        res.ResponseStatusCode ===
                        config.ResponseStatusCodes.Success &&
                        res.IsRestored
                      ) {
                        this.tableRef.current.onQueryChange();
                      }
                    });
                }
              }
            },
            {
              icon: this.state.dataFilter.showDelete ? 'check_box' : 'check_box_outline_blank',
              tooltip: this.state.dataFilter.showDelete ? myNextCompany.values.messages.textHiddenDeletedRecords : myNextCompany.values.messages.textShowDeletedRecords,
              isFreeAction: true,
              onClick: (event) => this.showDelete()
            },
          ]}
          editable={{
            onRowAdd: newData => {
              return areasOfExpertisePositionsRelationService.AddAreasOfExpertisePositionsRelationItem(newData);
            },
            isDeletable: (rowData) => !rowData.isdeleted,
            onRowDelete: oldData => {
              return areasOfExpertisePositionsRelationService.DeleteAreasOfExpertisePositionsRelationItem(oldData);
            }
          }}
        />
      </div>

    )
  }
}
