import { API } from '_utilities/API'

export const skillsService = {
    InitSkills: async () => {
        return await API.get("api/skills/init");
    },
    FilterSkills: async data => {
        return await API.post("api/skills/filter", data);
    },
    AddSkill: async data => {
        return await API.post("api/skills/add", data);
    },
    UpdateSkill: async data => {
        return await API.put("api/skills/update", data);
    },
    DeleteSkill: async data => {
        return await API.delete("api/skills/delete", data);
    },
    RestoreSkill: async data => {
        return await API.post("api/skills/restore", data);
    },
}

