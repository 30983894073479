import React, { Component } from "react";
import ActivityAreaSkills from "./ActivityAreaSkills";
import LoadingBar from "react-top-loading-bar";
import { activityAreasService } from "_services";

export default class ActivityAreaDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityAreaSkillsRelation: []
    };
  }

  componentDidMount() {
    this.LoadingBar.continuousStart();
    activityAreasService.DetailActivityAreas(this.props.detailActivityArea.code).then(data => {
      this.setState({ activityAreaSkillsRelation: data.ActivityAreaSkillsRelation, skills: data.Skills });
      this.LoadingBar.complete();
    });
  }

  render() {
    return (
      <div>
        <LoadingBar
          height={3}
          color="#f11946"
          onRef={ref => (this.LoadingBar = ref)}
        />
        <ActivityAreaSkills detailActivityArea={this.props.detailActivityArea} activityAreaSkillsRelation={this.state.activityAreaSkillsRelation} skills={this.state.skills} />
      </div>
    );
  }
}
