import React, { Component } from 'react';
import { API } from '_utilities/API';
import { toast } from 'react-toastify';
export default class NewAccountModal extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const { refs } = this;
    var data = {
      email: refs.email.value,
      lastname: refs.lastname.value,
      firstname: refs.firstname.value,
      password: refs.password.value,
      confirm_password: refs.confirm_password.value,
    };
    if (data.password !== data.confirm_password) {
      alert('Passwords must match!');
      return;
    }

    API.post('api/admin', data).then((res) => {
      if (res.result) {
        toast.success('New account has been created!');
        refs.btnCancel.click();
      } else {
        alert(res.message);
      }
    });
  };

  render() {
    return (
      <div
        className='modal fade'
        id='newAccountModal'
        tabIndex='-1'
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>New Account</h5>
              <button
                className='close'
                type='button'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <form onSubmit={this.handleSubmit} role='form'>
              <div className='modal-body'>
                <div className='form-group'>
                  <label className='sr-only'>Email address</label>
                  <input
                    type='email'
                    ref='email'
                    required
                    className='form-control'
                    placeholder='Email address'
                  />
                </div>
                <div className='form-group'>
                  <label className='sr-only'>First name</label>
                  <input
                    type='text'
                    ref='firstname'
                    required
                    className='form-control'
                    placeholder='First name'
                  />
                </div>
                <div className='form-group'>
                  <label className='sr-only'>Last name</label>
                  <input
                    type='text'
                    ref='lastname'
                    required
                    className='form-control'
                    placeholder='Last name'
                  />
                </div>
                <div className='form-group'>
                  <label className='sr-only'>Password</label>
                  <input
                    type='password'
                    ref='password'
                    required
                    className='form-control'
                    placeholder='Password'
                  />
                </div>
                <div className='form-group'>
                  <label className='sr-only'>Confirm password</label>
                  <input
                    type='password'
                    ref='confirm_password'
                    required
                    className='form-control'
                    placeholder='Confirm password'
                  />
                </div>
              </div>
              <div className='modal-footer'>
                <button className='btn btn-success' type='submit'>
                  Save
                </button>
                <button
                  className='btn btn-danger'
                  type='button'
                  ref='btnCancel'
                  data-dismiss='modal'
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
