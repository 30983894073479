import React, { Component } from 'react';
import LoadingBar from 'react-top-loading-bar';
import MaterialTable from 'material-table';
import { candidatesService } from '_services';
import CandidatesFilter from './CandidatesFilter';
import { Link } from 'react-router-dom';
import { myNextCompany } from '_utilities/common';
import * as config from '_utilities/config';
import { API } from '_utilities/API';
import { toast } from 'react-toastify';
import ToggleButton from 'components/ToggleButton';
export default class Candidates extends Component {
  tableRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          title: 'Candidate name',
          field: 'candidate_name',
          render: rowData => (
            <Link to={'/candidates/detail/' + rowData.candidate_id}>
              {rowData.candidate_name}
            </Link>
          ),
        },
        {
          title: 'Registration date',
          field: 'registration_date_display',
        },
        { title: 'Language(s)', field: 'languages' },
        {
          title: 'Hire type',
          field: 'user_contract',
          render: rowData =>
            myNextCompany.extensions.getContractInfo(
              rowData.user_contract,
            ).contractsLabel,
        },
        { title: 'Sectors', field: 'sectors' },
        { title: 'Position', field: 'position' },
        { title: 'Skill(s)', field: 'skills' },
        {
          title: 'Active',
          render: rowData => {
            return (
              <ToggleButton
                value={rowData.isactive}
                onChange={() => {
                  this.statusUpdate(rowData.candidate_id);
                }}
              />
            );
          },
        },
        {
          title: 'Status',
          render: rowData => {
            if (rowData.status === 'VERIFIED')
              return <span style={{ color: 'green' }}>VERIFIED</span>;
            else if (rowData.status === 'LOCKED')
              return (
                <button
                  className='btn btn-outline-danger c-pointer'
                  type='button'
                  onClick={() => this.unlock(rowData.candidate_id)}
                >
                  Unlock
                </button>
              );
            else if (rowData.status === 'PENDING')
              return (
                <button
                  className='btn btn-outline-danger c-pointer'
                  type='button'
                  onClick={() =>
                    this.resendValidationEmail(rowData.candidate_id)
                  }
                >
                  Re-send
                </button>
              );
            else return <span>{rowData.status}</span>;
          },
        },
        {
          title: 'Archive',
          render: rowData => {
            return rowData.isarchived ? (
              <button
                className='btn btn-outline-success c-pointer'
                type='button'
                style={{ whiteSpace: 'nowrap' }}
                onClick={() =>
                  this.archiveOrRestore(rowData.candidate_id)
                }
              >
                {rowData.isarchived} Restore
              </button>
            ) : (
              <button
                className='btn btn-outline-danger c-pointer'
                type='button'
                style={{ whiteSpace: 'nowrap' }}
                onClick={() =>
                  this.archiveOrRestore(rowData.candidate_id)
                }
              >
                {rowData.isarchived} Archive
              </button>
            );
          },
        },
      ],
      languages: [],
      skills: [],
      positions: [],
      areasOfExpertise: [],
      dataFilter: { isvalidcontent: true },
      options: {
        ...myNextCompany.values.materialTableOptions,
        search: false,
      },
    };
  }
  componentDidMount() {
    this.LoadingBar.continuousStart();

    candidatesService.InitCandidates().then(data => {
      this.setState({
        languages: data.Languages,
        skills: data.Skills,
        positions: data.Positions,
        areasOfExpertise: data.AreasOfExpertise,
      });
      this.LoadingBar.complete();
    });
  }
  resendValidationEmail = id => {
    API.get('api/candidates/ResendValidationEmail?id=' + id).then(
      res => {
        if (res.result) {
          toast.success('Email has sent.');
        } else {
          toast.error('An error has occurred!');
        }
      },
    );
  };
  unlock = id => {
    API.get('api/candidates/Unlock?id=' + id).then(res => {
      if (res.result) {
        toast.success('Save changed.');
        this.tableRef.current.onQueryChange();
      } else {
        toast.error('An error has occurred!');
      }
    });
  };
  archiveOrRestore = id => {
    API.get('api/candidates/ArchiveOrRestore?id=' + id).then(res => {
      if (res.result) {
        toast.success('Save changed.');
        this.tableRef.current.onQueryChange();
      } else {
        toast.error('An error has occurred!');
      }
    });
  };
  statusUpdate = id => {
    API.get('api/candidates/ToggleActive?id=' + id).then(res => {
      if (res.result) {
        toast.success('Save changed.');
        this.tableRef.current.onQueryChange();
      } else {
        toast.error('An error has occurred!');
      }
    });
  };

  filterCandidates = dataFilter => {
    this.setState({
      dataFilter: dataFilter,
    });
    this.tableRef.current.onQueryChange();
  };
  render() {
    return (
      <div>
        <CandidatesFilter
          filterCandidates={this.filterCandidates}
          languages={this.state.languages}
          positions={this.state.positions}
          skills={this.state.skills}
          areasOfExpertise={this.state.areasOfExpertise}
        />

        <LoadingBar
          height={3}
          color='#f11946'
          onRef={ref => (this.LoadingBar = ref)}
        />
        <MaterialTable
          title='Candidates'
          tableRef={this.tableRef}
          columns={this.state.columns}
          options={this.state.options}
          onRowClick={(evt, selectedRow) =>
            this.setState({ selectedRow })
          }
          data={query =>
            new Promise((resolve, reject) => {
              this.LoadingBar.continuousStart();

              let dataFilter = myNextCompany.extensions.matchingDataFilter(
                {
                  ...this.state.dataFilter,
                  ...query,
                },
              );

              candidatesService
                .FilterCandidates(dataFilter)
                .then(data => {
                  this.setState({ dataFilter: dataFilter });

                  resolve({
                    data: data.CandidateFilter.Data,
                    page: data.CandidateFilter.Page,
                    totalCount: data.CandidateFilter.TotalRecords,
                  });

                  this.LoadingBar.complete();
                });
            })
          }
          onChangeRowsPerPage={page => {
            this.setState({
              options: {
                ...this.state.options,
                pageSize: page,
              },
            });
          }}
        />
      </div>
    );
  }
}
