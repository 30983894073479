import { API } from '_utilities/API'

export const jobOffersService = {
    InitJobOffers: async () => {
        return await API.get("api/joboffers/init");
    },
    JobOfferDetail: async (jobOfferId) => {
        return await API.get("api/joboffers/detail/?jobOfferId=" + jobOfferId);
    },
    FilterJobOffers: async data => {
        return await API.post("api/joboffers/filter", data);
    },
    AddJobOffer: async data => {
        return await API.post("api/joboffers/add", data);
    },
    UpdateJobOfferDetail: async data => {
        return await API.put("api/joboffers/updatejobofferdetail", data);
    },
    DeleteJobOffer: async data => {
        return await API.delete("api/joboffers/delete", data);
    },
    JobOfferUploadPicture: async data => {
        return await API.post("api/joboffers/uploadpicture", data);
    },
    JobOfferDeletePicture: async data => {
        return await API.delete("api/joboffers/jobofferdeletepicture", data);
    },
    JobOfferUploadVideo: async data => {
        return await API.post("api/joboffers/jobofferuploadvideo", data);
    },
    JobOfferDeleteVideo: async data => {
        return await API.delete("api/joboffers/jobofferdeletevideo", data);
    },
}

