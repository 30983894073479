import { API } from '_utilities/API'

export const companyEmailRestrictedService = {
    InitCompanyEmailRestricted: async () => {
        return await API.get("api/CompanyEmailRestricted/init");
    },
    FilterCompanyEmailRestricted: async data => {
        return await API.post("api/CompanyEmailRestricted/filter", data);
    },
    AddCompanyEmailRestricted: async data => {
        return await API.post("api/CompanyEmailRestricted/add", data);
    },
    UpdateCompanyEmailRestricted: async data => {
        return await API.put("api/CompanyEmailRestricted/update", data);
    },
    DeleteCompanyEmailRestricted: async data => {
        return await API.delete("api/CompanyEmailRestricted/delete", data);
    },
    RestoreCompanyEmailRestricted: async data => {
        return await API.post("api/CompanyEmailRestricted/restore", data);
    },
}

