import { LoaderButton } from 'components/LoaderButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { alertActions, authenticateActions } from '_actions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }
  componentDidMount() {
    this.refs.email.autoFocus = true;
  }
  validateForm = () => {
    return (
      this.state.email.length > 0 && this.state.password.length > 0
    );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { email, password } = this.state;
      this.props.login(email, password);
    } catch (e) {
      this.props.setErrorAlerts(e.message);
    }
  };

  render() {
    const { alert } = this.props;
    return (
      <div className='login-container bg-gradient-primary'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-10 col-lg-12 col-md-9'>
              <div className='card o-hidden border-0 shadow-lg my-5'>
                <div className='card-body p-0'>
                  <div className='row'>
                    <div className='col-lg-6 d-none d-lg-block bg-login-image'></div>
                    <div className='col-lg-6'>
                      <div className='p-5'>
                        <div className='text-center'>
                          <h1 className='h4 text-gray-900 mb-4'>
                            Welcome Back!
                          </h1>
                        </div>
                        <form
                          className='user'
                          onSubmit={this.handleSubmit}
                        >
                          <div className='form-group'>
                            <input
                              type='email'
                              autoFocus
                              className='form-control form-control-user'
                              onChange={this.handleChange}
                              name='email'
                              placeholder='Enter Email Address...'
                              ref='email'
                            />
                          </div>
                          <div className='form-group'>
                            <input
                              type='password'
                              className='form-control form-control-user'
                              onChange={this.handleChange}
                              name='password'
                              placeholder='Password'
                            />
                          </div>
                          <div className='form-group'>
                            <div className='custom-control custom-checkbox small'>
                              <input
                                type='checkbox'
                                className='custom-control-input'
                                id='customCheck'
                              />
                              <label
                                className='custom-control-label'
                                htmlFor='customCheck'
                              >
                                Remember Me
                              </label>
                            </div>
                          </div>
                          {/* <button className="btn btn-primary btn-user btn-block" disabled={!this.validateForm()}>Login</button> */}
                          <LoaderButton
                            className='btn btn-primary btn-user btn-block'
                            disabled={!this.validateForm()}
                            type='submit'
                            isLoading={this.props.loggingIn}
                            text='Login'
                            loadingText='Logging in…'
                          />
                          {alert.message && (
                            <div
                              style={{ marginTop: '10px' }}
                              className={`alert ${alert.type}`}
                            >
                              {alert.message}
                            </div>
                          )}
                        </form>
                        <hr />
                        <div className='text-center'>
                          <a
                            className='small'
                            href='forgot-password.html'
                          >
                            Forgot Password?
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapState(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return { loggingIn, alert };
}

const actionCreators = {
  login: authenticateActions.login,
  logout: authenticateActions.logout,
  clearAlerts: alertActions.clear,
  setInfoAlerts: alertActions.success,
  setErrorAlerts: alertActions.error,
};

export default connect(mapState, actionCreators)(Login);
