import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authenticateActions } from '_actions';
class LogoutModal extends Component {
  handleClick = () => {
    this.props.logout();
  };
  render() {
    return (
      <div
        className='modal fade'
        id='logoutModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Ready to Leave?
              </h5>
              <button
                className='close'
                type='button'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <div className='modal-body'>
              Select "Logout" below if you are ready to end your current
              session.
            </div>
            <div className='modal-footer'>
              <button
                className='btn btn-secondary'
                type='button'
                data-dismiss='modal'
              >
                Cancel
              </button>
              <button
                className='btn btn-primary'
                type='button'
                data-dismiss='modal'
                onClick={this.handleClick}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const actionCreators = {
  logout: authenticateActions.logout
};

export default connect(
  null,
  actionCreators
)(LogoutModal);
