import React, { Component } from 'react';
import LoadingBar from 'react-top-loading-bar';
import MaterialTable from 'material-table';
import { userCompaniesService } from '_services';
import UserCompaniesFilter from './UserCompaniesFilter';
import { Link } from 'react-router-dom';
import { myNextCompany } from '_utilities/common';
import { API } from '_utilities/API';
import { toast } from 'react-toastify';

export default class UserCompanies extends Component {
  tableRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          title: 'Company name',
          field: 'companyname',
          filtering: true,
          render: (rowData) => (
            <Link to={'/companies/detail/' + rowData.company_id}>
              {rowData.companyname
                ? rowData.companyname
                : 'Not defined yet'}
            </Link>
          ),
        },
        {
          title: 'Registration date',
          field: 'registration_date_display',
        },
        { title: 'Activity Area', field: 'activityarea_defaultname' },
        { title: 'Postcode', field: 'zipcode' },
        { title: 'Country', field: 'countryname' },
        { title: 'Manager', field: 'user_fullname' },
        {
          title: 'Manager function',
          field: 'jobfunction_defaultname',
        },
        { title: 'Email', field: 'email' },
        {
          title: 'Status',
          render: (rowData) => {
            if (rowData.status === 'VERIFIED')
              return <span style={{ color: 'green' }}>VERIFIED</span>;
            else if (rowData.status === 'LOCKED')
              return (
                <button
                  className='btn btn-outline-danger c-pointer'
                  style={{ whiteSpace: 'nowrap' }}
                  onClick={() => this.unlock(rowData.user_id)}
                >
                  Unlock
                </button>
              );
            else if (rowData.status === 'PENDING')
              return (
                <button
                  className='btn btn-outline-danger c-pointer'
                  style={{ whiteSpace: 'nowrap' }}
                  onClick={() =>
                    this.resendValidationEmail(rowData.user_id)
                  }
                >
                  Re-send
                </button>
              );
            else return <span>{rowData.status}</span>;
          },
        },
        {
          title: 'Archive',
          render: (rowData) => {
            return rowData.isarchived ? (
              <button
                className='btn btn-outline-success c-pointer'
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => this.archiveOrRestore(rowData.user_id)}
              >
                {rowData.isarchived} Restore
              </button>
            ) : (
              <button
                className='btn btn-outline-danger c-pointer'
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => this.archiveOrRestore(rowData.user_id)}
              >
                {rowData.isarchived} Archive
              </button>
            );
          },
        },
      ],
      activityAreas: [],
      countries: [],
      dataFilter: {},
      options: {
        ...myNextCompany.values.materialTableOptions,
        search: false,
      },
    };
  }
  archiveOrRestore = (id) => {
    API.get('api/candidates/ArchiveOrRestore?id=' + id).then(
      (res) => {
        if (res.result) {
          toast.success('Save changed.');
          this.tableRef.current.onQueryChange();
        } else {
          toast.error('An error has occurred!');
        }
      },
    );
  };
  resendValidationEmail = (id) => {
    API.get('api/candidates/ResendValidationEmail?id=' + id).then(
      (res) => {
        if (res.result) {
          toast.success('Email has sent.');
        } else {
          toast.error('An error has occurred!');
        }
      },
    );
  };
  unlock = (id) => {
    API.get('api/candidates/Unlock?id=' + id).then((res) => {
      if (res.result) {
        toast.success('Save changed.');
        this.tableRef.current.onQueryChange();
      } else {
        toast.error('An error has occurred!');
      }
    });
  };
  filterUserCompanies = (dataFilter) => {
    this.setState({
      dataFilter: dataFilter,
    });
    this.tableRef.current.onQueryChange();
  };

  componentDidMount() {
    this.LoadingBar.continuousStart();

    userCompaniesService.InitUserCompanies().then((data) => {
      this.setState({
        activityAreas: data.ActivityAreas,
        countries: data.Countries,
      });
      this.LoadingBar.complete();
    });
  }

  render() {
    return (
      <div>
        <UserCompaniesFilter
          filterUserCompanies={this.filterUserCompanies}
          activityAreas={this.state.activityAreas}
          countries={this.state.countries}
        />

        <LoadingBar
          height={3}
          color='#f11946'
          onRef={(ref) => (this.LoadingBar = ref)}
        />
        <MaterialTable
          title='Companies'
          tableRef={this.tableRef}
          columns={this.state.columns}
          options={this.state.options}
          onRowClick={(evt, selectedRow) =>
            this.setState({ selectedRow })
          }
          data={(query) =>
            new Promise((resolve, reject) => {
              this.LoadingBar.continuousStart();

              let dataFilter = myNextCompany.extensions.matchingDataFilter(
                {
                  ...this.state.dataFilter,
                  ...query,
                },
              );

              userCompaniesService
                .FilterUserCompanies(dataFilter)
                .then((data) => {
                  this.setState({ dataFilter: dataFilter });

                  resolve({
                    data: data.UserCompanyFilter.Data,
                    page: data.UserCompanyFilter.Page,
                    totalCount: data.UserCompanyFilter.TotalRecords,
                  });

                  this.LoadingBar.complete();
                });
            })
          }
          onChangeRowsPerPage={(page) => {
            this.setState({
              options: {
                ...this.state.options,
                pageSize: page,
              },
            });
          }}
        />
      </div>
    );
  }
}
