import React, { Component } from 'react';
import { userCompaniesService } from '_services';
import { myNextCompany } from '_utilities/common';
import * as config from '_utilities/config';
import { API } from '_utilities/API';
import { toast } from 'react-toastify';
import UserStatus from 'components/UserStatus';

export default class UserCompanyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userCompany: this.props.userCompany,
      confirmationLink: '',
    };
  }
  fileChangedHandler = (event) => {
    const data = new FormData();
    data.append('file', event.target.files[0]);
    data.append('UserId', this.state.userCompany.user_id);
    data.append('CompanyId', this.state.userCompany.company_id);
    userCompaniesService
      .UserCompanyUploadProfile(data)
      .then((res) => {
        if (
          !myNextCompany.extensions.isUndefOrNull(res.UserCompany)
        ) {
          this.setState({
            userCompany: res.UserCompany,
          });
        }
      });
    event.target.value = '';
  };

  userCompanyDeleteProfile(userCompany) {
    var data = {
      CompanyId: userCompany.company_id,
      UserId: userCompany.user_id,
      MediaId: userCompany.u_picture_media_id,
    };
    userCompaniesService
      .UserCompanyDeleteProfile(data)
      .then((res) => {
        if (
          res.ResponseStatusCode ===
            config.ResponseStatusCodes.Success &&
          res.IsDeleted
        ) {
          if (
            !myNextCompany.extensions.isUndefOrNull(res.UserCompany)
          ) {
            this.setState({
              userCompany: res.UserCompany,
            });
          }
        }
      });
  }

  uploadProfile = () => {
    document.getElementById('upload-profile').click();
  };
  resendConfirmationEmail = (id) => {
    API.get('api/candidates/ResendValidationEmail?id=' + id).then(
      (res) => {
        if (res.result) {
          toast.success('Email has sent.');
        } else {
          toast.error('An error has occurred!');
        }
      },
    );
  };
  getConfirmationLink = (id) => {
    API.get('api/candidates/GetConfirmationLink?id=' + id).then(
      (res) => {
        if (res.result) {
          this.setState({ confirmationLink: res.confirmationLink });
        } else {
          toast.error('An error has occurred!');
        }
      },
    );
  };
  render() {
    return (
      <div>
        <div className='content-box'>
          <div className='form-group'>
            <div className='card bg-transparent border-1'>
              <div className='row'>
                <div className='col-2'>
                  <div
                    className='c-pointer'
                    onClick={this.uploadProfile}
                  >
                    <input
                      type='file'
                      id='upload-profile'
                      className='d-none'
                      onChange={this.fileChangedHandler}
                    />
                    <span
                      className='badge badge-pill badge-success position-absolute badge-top-left'
                      style={{ left: '35px' }}
                    >
                      <i className='fas fa-fw fa-upload'></i>
                    </span>
                  </div>

                  {!myNextCompany.extensions.isUndefOrNull(
                    this.state.userCompany.u_picture_media_id,
                  ) ? (
                    <div>
                      <img
                        src={this.state.userCompany.u_picture_url}
                        className='img-candidate card-img rounded-circle m-3'
                        alt='...'
                      />
                      <div
                        className='c-pointer'
                        onClick={() => {
                          this.userCompanyDeleteProfile(
                            this.state.userCompany,
                          );
                        }}
                      >
                        <span
                          className='badge badge-pill badge-danger position-absolute badge-top-left'
                          style={{ top: '50px', left: '25px' }}
                        >
                          <i className='fas fa-fw fa-trash'></i>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <img
                      src={myNextCompany.values.uploadedLogo}
                      className='img-candidate card-img rounded-circle m-3'
                      alt='...'
                    />
                  )}
                </div>
                <div className='card-body'>
                  <div className='heading'>
                    <h2>{this.state.userCompany.user_fullname}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-md-6 mb-3'>
              <h2>First name</h2>
              <p>{this.state.userCompany.firstname}</p>
            </div>
            <div className='col-md-6 mb-3'>
              <h2>Last name</h2>
              <p>{this.state.userCompany.lastname}</p>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-md-6 mb-3'>
              <h2>Email Address</h2>
              <p>{this.state.userCompany.email}</p>
              <h2>Phone Number</h2>
              <p>{this.state.userCompany.phonenumber}</p>

              <UserStatus
                userId={this.state.userCompany.user_id}
                status={this.state.userCompany.status}
                isactive={this.state.userCompany.isactive}
                isarchived={this.state.userCompany.isarchived}
                confirmationLink={this.state.confirmationLink}
                resendConfirmationEmail={this.resendConfirmationEmail}
                getConfirmationLink={this.getConfirmationLink}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <h2>Your job function</h2>
              <p>{this.state.userCompany.jobfunction_defaultname}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
