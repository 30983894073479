import axios from 'axios';
import { toast } from 'react-toastify';
import { authenticateActions } from '_actions/authenticate.action';
import { myNextCompany } from './common';
import * as config from '_utilities/config';

class axiosService {
  constructor() {
    const service = axios.create({
      headers: {},
    });
    const token = localStorage.getItem('token');
    service.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token}`;
    service.interceptors.response.use(
      this.handleSuccess,
      this.handleError,
    );
    this.service = service;
  }
  setHeaders(headers) {
    this.service.defaults.headers = headers;
  }
  setHeader(name, value) {
    this.service.defaults.headers.common[name] = value;
  }

  removeHeader(name) {
    delete this.service.defaults.headers.common[name];
  }

  handleSuccess(res) {
    if (
      res.data.ResponseStatusCode ===
        config.ResponseStatusCodes.Error &&
      !myNextCompany.extensions.isUndefOrNull(res.data.Message)
    ) {
      toast.error(res.data.Message);
    }
    if (
      res.data.ResponseStatusCode ===
        config.ResponseStatusCodes.Success &&
      !myNextCompany.extensions.isUndefOrNull(res.data.Message)
    ) {
      toast.success(res.data.Message);
    }
    return res.data;
  }

  handleError = (error) => {
    switch (error.response && error.response.status) {
      case 400: // login fail
        return Promise.reject(error.response.data);
      case 401: // lost session
        authenticateActions.logout();
        break;
      case 500: // internal server error
        toast.error(
          'Message:' +
            error.response.data.Message +
            'Message:' +
            error.response.data.ExceptionMessage,
        );
        return Promise.reject({
          error: error.response.data.Message,
          error_description: error.response.data.ExceptionMessage,
        });
      default:
        toast.error('An error has occurred!');
        return Promise.reject({
          error: 'Error',
          error_description: 'An error has occurred!',
        });
    }
  };
  login(endpoint, payload) {
    return this.service.request({
      method: 'POST',
      url: endpoint,
      responseType: 'json',
      data: payload,
    });
  }
  get(endpoint) {
    return this.service.get(endpoint);
  }

  post(endpoint, payload) {
    return this.service.request({
      method: 'POST',
      url: endpoint,
      responseType: 'json',
      data: payload,
    });
  }

  put(endpoint, payload) {
    return this.service.request({
      method: 'PUT',
      url: endpoint,
      responseType: 'json',
      data: payload,
    });
  }

  delete(endpoint, payload) {
    return this.service.request({
      method: 'DELETE',
      url: endpoint,
      responseType: 'json',
      data: payload,
    });
  }
}

export default new axiosService();
