import { API } from '_utilities/API';

export const languagesByCountryService = {
  InitLanguagesByCountry: async () => {
    return await API.get("api/languagesbycountry/init");
  },
  FilterLanguagesByCountry: async data => {
    return await API.post("api/languagesbycountry/filter", data);
  },
  AddLanguageByCountry: async data => {
    return await API.post("api/languagesbycountry/add", data);
  },
  UpdateLanguageByCountry: async data => {
    return await API.put("api/languagesbycountry/update", data);
  },
  DeleteLanguageByCountry: async data => {
    return await API.delete("api/languagesbycountry/delete", data);
  },
  RestoreLanguageByCountry: async data => {
    return await API.post("api/languagesbycountry/restore", data);
  },
};
