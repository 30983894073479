import { API } from '_utilities/API'

export const activityAreaSkillsRelationService = {
    InitActivityAreaSkillsRelation: async () => {
        return await API.get("api/activityareaskillsrelation/init");
    },
    FilterActivityAreaSkillsRelation: async data => {
        return await API.post("api/activityareaskillsrelation/filter", data);
    },
    AddActivityAreaSkillRelation: async data => {
        return await API.post("api/activityareaskillsrelation/add", data);
    },
    UpdateActivityAreaSkillRelation: async data => {
        return await API.put("api/activityareaskillsrelation/update", data);
    },
    DeleteActivityAreaSkillRelation: async data => {
        return await API.delete("api/activityareaskillsrelation/delete", data);
    },
    RestoreActivityAreaSkillRelation: async data => {
        return await API.post("api/activityareaskillsrelation/restore", data);
    },
}