import { authenticateConstants } from '_constants';

let user = localStorage.getItem('token');
const initialState = user ? { loggedIn: true, user } : { loggingIn: false };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authenticateConstants.LOGIN_REQUEST:
      return {
        loggingIn: true
      };
    case authenticateConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case authenticateConstants.LOGIN_FAILURE:
      return {};
    case authenticateConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
