import { API } from '_utilities/API'

export const companiesService = {
    InitCompanies: async () => {
        return await API.get("api/companies/init");
    },
    FilterCompanies: async data => {
        return await API.post("api/companies/filter", data);
    },
    AddCompany: async data => {
        return await API.post("api/companies/add", data);
    },
    UpdateCompany: async data => {
        return await API.put("api/companies/update", data);
    },
    DeleteCompany: async data => {
        return await API.delete("api/companies/delete", data);
    },
    RestoreCompany: async data => {
        return await API.post("api/companies/restore", data);
    },
}

