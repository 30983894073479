import React, { Component } from 'react';
import LoadingBar from 'react-top-loading-bar';
import MaterialTable from 'material-table';
import { myNextCompany } from '_utilities/common';
import JobOffersFilter from './JobOffersFilter';
import { Link } from 'react-router-dom';
import { jobOffersService } from '_services';
import { API } from '_utilities/API';
import { toast } from 'react-toastify';
export default class JobOffers extends Component {
  tableRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: 'ID', field: 'id', filtering: false },
        { title: 'Job Title', field: 'jobtitle' },
        { title: 'Company', field: 'company_id', lookup: {} },
        { title: 'Area of expertise', field: 'areasofexpertise' },
        { title: 'Skills', field: 'skills' },
        {
          title: 'Job Description',
          field: 'jobdescription',
          filtering: false,
        },
        { title: 'Languages', field: 'languages' },
        {
          title: 'Monthly Salary',
          field: 'monthlysalary',
          filtering: false,
        },
        { title: 'Daily Rate', field: 'dailyrate', filtering: false },
        {
          title: 'Posted on',
          field: 'posted_date_display',
          filtering: false,
          headerStyle: { width: 120 },
        },
      ],
      companies: [],
      areasOfExpertise: [],
      languages: [],
      monthlySalaries: [],
      dailyRates: [],
      dataFilter: { isvalidcontent: true },
      options: {
        ...myNextCompany.values.materialTableOptions,
        search: false,
      },
    };
  }

  componentDidMount() {
    this.LoadingBar.continuousStart();
    jobOffersService.InitJobOffers().then((data) => {
      this.setState({
        columns: [
          { title: 'ID', field: 'id', filtering: false },
          {
            title: 'Job Title',
            field: 'jobtitle',
            render: (rowData) => (
              <Link to={'/joboffers/detail/' + rowData.id}>
                {rowData.jobtitle
                  ? rowData.jobtitle
                  : 'Not defined yet'}
              </Link>
            ),
          },
          {
            title: 'Company',
            field: 'companyname',
            render: (rowData) => (
              <Link to={'/companies/detail/' + rowData.company_id}>
                {rowData.companyname
                  ? rowData.companyname
                  : 'Not defined yet'}
              </Link>
            ),
            lookup: myNextCompany.extensions.arrayToObject(
              data.Companies,
              'companyname',
              'companyname',
            ),
          },
          {
            title: 'Area of expertise',
            field: 'areasofexpertise',
            filtering: false,
          },
          { title: 'Skills', field: 'skills', filtering: false },

          {
            title: 'Job Description',
            field: 'jobdescription',
            render: (rowData) => (
              <div title={rowData.jobdescription}>
                {rowData.jobdescription_short}
              </div>
            ),
            filtering: false,
          },

          {
            title: 'Languages',
            field: 'languages',
            filtering: false,
          },
          {
            title: 'Monthly Salary',
            field: 'monthlysalary',
            filtering: false,
          },
          {
            title: 'Daily Rate',
            field: 'dailyrate',
            filtering: false,
          },
          {
            title: 'Posted on',
            field: 'posted_date_display',
            filtering: false,
            headerStyle: { width: 120 },
          },
          {
            title: 'Archive',
            render: (rowData) => {
              return rowData.isarchived ? (
                <button
                  className='btn btn-outline-success c-pointer'
                  style={{ whiteSpace: 'nowrap' }}
                  onClick={() => this.archiveOrRestore(rowData.id)}
                >
                  {rowData.isarchived} Restore
                </button>
              ) : (
                <button
                  className='btn btn-outline-danger c-pointer'
                  style={{ whiteSpace: 'nowrap' }}
                  onClick={() => this.archiveOrRestore(rowData.id)}
                >
                  {rowData.isarchived} Archive
                </button>
              );
            },
          },
        ],
        companies: data.Companies,
        areasOfExpertise: data.AreasOfExpertise,
        languages: data.Languages,
        monthlySalaries: data.MonthlySalaries,
        dailyRates: data.DailyRates,
      });
      this.LoadingBar.complete();
    });
  }

  filterJobOffers = (dataFilter) => {
    this.setState({
      dataFilter: dataFilter,
    });
    this.tableRef.current.onQueryChange();
  };
  archiveOrRestore = (id) => {
    API.get('api/joboffers/ArchiveOrRestore?id=' + id).then((res) => {
      if (res.result) {
        toast.success('Save changed.');
        this.tableRef.current.onQueryChange();
      } else {
        toast.error('An error has occurred!');
      }
    });
  };
  render() {
    return (
      <div>
        <JobOffersFilter
          filterJobOffers={this.filterJobOffers}
          areasOfExpertise={this.state.areasOfExpertise}
          languages={this.state.languages}
          monthlySalaries={this.state.monthlySalaries}
          dailyRates={this.state.dailyRates}
        />

        <LoadingBar
          height={3}
          color='#f11946'
          onRef={(ref) => (this.LoadingBar = ref)}
        />
        <MaterialTable
          title='Job Offers'
          tableRef={this.tableRef}
          columns={this.state.columns}
          options={this.state.options}
          onRowClick={(evt, selectedRow) =>
            this.setState({ selectedRow })
          }
          data={(query) =>
            new Promise((resolve, reject) => {
              this.LoadingBar.continuousStart();
              let dataFilter = myNextCompany.extensions.matchingDataFilter(
                {
                  ...this.state.dataFilter,
                  ...query,
                },
              );

              jobOffersService
                .FilterJobOffers(dataFilter)
                .then((data) => {
                  this.setState({ dataFilter: dataFilter });

                  resolve({
                    data: data.JobOfferFilter.Data,
                    page: data.JobOfferFilter.Page,
                    totalCount: data.JobOfferFilter.TotalRecords,
                  });

                  this.LoadingBar.complete();
                });
            })
          }
          onChangeRowsPerPage={(page) => {
            this.setState({
              options: {
                ...this.state.options,
                pageSize: page,
              },
            });
          }}
        />
      </div>
    );
  }
}
