import React, { Component } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { myNextCompany } from '_utilities/common';
import { jobOffersService, jobOfferPictures } from '_services';
import NoDataFoundPage from 'pages/NoDataFound';
import { Link } from 'react-router-dom';
import * as config from '_utilities/config';
import 'video-react/dist/video-react.css'; // import css
import { Player } from 'video-react';

export default class JobOfferDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      jobOffer: null,
      jobOfferPackAdvantagesExtended: [],
      jobOfferPositionsExtended: [],
      jobOfferSkillsExtended: [],
      jobOfferLanguagesExtended: [],
      jobOfferPicturesExtended: [],
      contractInfo: {},
    };
  }

  onChange = (e) => {
    var jobOffer = this.state.jobOffer;
    jobOffer[e.target.name] = e.target.value;

    this.setState({
      jobOffer: jobOffer,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.LoadingBar.continuousStart();

    let data = {
      JobOffer: {
        ...this.state.jobOffer,
      },
    };

    jobOffersService.UpdateJobOfferDetail(data).then((data) => {
      if (
        !myNextCompany.extensions.isUndefOrNull(
          data.JobOfferDetail.JobOffer,
        )
      ) {
        this.setState({
          jobOffer: data.JobOfferDetail.JobOffer,
        });
      }
      this.LoadingBar.complete();
    });
  };

  componentDidMount() {
    this.LoadingBar.continuousStart();
    let jobOfferId = this.props.match.params.jobOfferId;
    if (!myNextCompany.extensions.isUndefOrNull(jobOfferId)) {
      jobOffersService.JobOfferDetail(jobOfferId).then((data) => {
        this.setState({
          jobOffer: data.JobOfferDetail.JobOffer,
          jobOfferPackAdvantagesExtended:
            data.JobOfferDetail.JobOfferPackAdvantagesExtended,
          jobOfferPositionsExtended:
            data.JobOfferDetail.JobOfferPositionsExtended,
          jobOfferSkillsExtended:
            data.JobOfferDetail.JobOfferSkillsExtended,
          jobOfferLanguagesExtended:
            data.JobOfferDetail.JobOfferLanguagesExtended,
          jobOfferPicturesExtended:
            data.JobOfferDetail.JobOfferPicturesExtended,
          isLoading: false,
          contractInfo: myNextCompany.extensions.getContractInfo(
            data.JobOfferDetail.JobOffer.contract,
          ),
        });
        this.LoadingBar.complete();
      });
    }
  }

  filePictureChangedHandler = (event) => {
    if (
      !myNextCompany.extensions.isUndefOrNull(event.target.files) &&
      event.target.files.length
    ) {
      for (
        let index = 0;
        index < event.target.files.length;
        index++
      ) {
        const file = event.target.files[index];
        const data = new FormData();
        data.append('file', file);
        data.append('id', this.state.jobOffer.id);
        jobOffersService.JobOfferUploadPicture(data).then((res) => {
          if (
            !myNextCompany.extensions.isUndefOrNull(
              res.JobOfferDetail.JobOfferPicturesExtended,
            )
          ) {
            this.setState({
              jobOfferPicturesExtended:
                res.JobOfferDetail.JobOfferPicturesExtended,
            });
          }
        });
      }
    }
    event.target.value = '';
  };

  uploadPicture = () => {
    document.getElementById('upload-picture').click();
  };

  jobOfferDeletePicture(jobOfferPicture) {
    jobOfferPictures
      .DeleteJobOfferPicture(jobOfferPicture)
      .then((res) => {
        if (
          res.ResponseStatusCode ===
            config.ResponseStatusCodes.Success &&
          res.IsDeleted
        ) {
          let jobOfferPicturesExtended = this.state
            .jobOfferPicturesExtended;
          const index = jobOfferPicturesExtended.indexOf(
            jobOfferPicture,
          );
          jobOfferPicturesExtended.splice(index, 1);
          this.setState({ jobOfferPicturesExtended });
        }
      });
  }

  uploadVideo = () => {
    document.getElementById('upload-video').click();
  };

  fileChangedHandlerVideo = (event) => {
    const data = new FormData();
    data.append('file', event.target.files[0]);
    data.append('id', this.state.jobOffer.id);
    jobOffersService.JobOfferUploadVideo(data).then((res) => {
      if (
        !myNextCompany.extensions.isUndefOrNull(
          res.JobOfferDetail.JobOffer,
        )
      ) {
        this.setState({
          jobOffer: res.JobOfferDetail.JobOffer,
        });
      }
    });
    event.target.value = '';
  };

  jobOfferDeleteVideo(jobOffer) {
    var data = {
      id: jobOffer.id,
      MediaId: jobOffer.joboffer_video_media_id,
    };
    jobOffersService.JobOfferDeleteVideo(data).then((res) => {
      if (
        res.ResponseStatusCode ===
          config.ResponseStatusCodes.Success &&
        res.IsDeleted
      ) {
        if (
          !myNextCompany.extensions.isUndefOrNull(
            res.JobOfferDetail.JobOffer,
          )
        ) {
          this.setState({
            jobOffer: res.JobOfferDetail.JobOffer,
          });
        }
      }
    });
  }

  render() {
    return (
      <div>
        <LoadingBar
          height={3}
          color='#f11946'
          onRef={(ref) => (this.LoadingBar = ref)}
        />
        {!this.state.isLoading ? (
          !myNextCompany.extensions.isUndefOrNull(
            this.state.jobOffer,
          ) ? (
            <form onSubmit={this.onSubmit}>
              <div className='content-box'>
                <div className='text-right mb-3'>
                  <button type='submit' className='btn btn-primary'>
                    Save
                  </button>
                </div>
                <div className='form-group'>
                  <div className='card bg-transparent'>
                    <div className='row'>
                      <div className='col'>
                        <div className='row no-gutters'>
                          <img
                            src={this.state.jobOffer.company_picture}
                            className='img-candidate card-img rounded-circle'
                            alt='...'
                          />
                          <div className='card-body'>
                            <div className='heading'>
                              <h2>Company</h2>
                              <p>
                                <Link
                                  to={
                                    '/companies/detail/' +
                                    this.state.jobOffer.company_id
                                  }
                                >
                                  {this.state.jobOffer.companyname}
                                </Link>
                              </p>
                              <p>
                                {this.state.jobOffer.zipcode},{' '}
                                {this.state.jobOffer.countryname},{' '}
                                {this.state.jobOffer.city}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='card-body'>
                          <div className='heading'>
                            <h2>
                              {this.state.jobOffer.areasofexpertise}
                            </h2>
                            <input
                              className='form-control'
                              name='jobtitle'
                              onChange={this.onChange}
                              defaultValue={
                                this.state.jobOffer.jobtitle
                              }
                              placeholder='Job Title'
                            />
                            <textarea
                              className='form-control h-100px'
                              name='jobdescription'
                              placeholder='Job Description'
                              onChange={this.onChange}
                              defaultValue={
                                this.state.jobOffer.jobdescription
                              }
                            />
                            <div className='ml-4 mt-2'>
                              <input
                                className='form-check-input'
                                name='isvalidcontent'
                                type='checkbox'
                                checked={
                                  !this.state.jobOffer.isvalidcontent
                                }
                                onChange={() =>
                                  this.setState({
                                    jobOffer: {
                                      ...this.state.jobOffer,
                                      isvalidcontent: !this.state
                                        .jobOffer.isvalidcontent,
                                    },
                                  })
                                }
                              />
                              <label className='form-check-label'>
                                Profanity
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='card-body'>
                          <div className='heading'>
                            <h2>Advantages</h2>
                            <ul>
                              {!myNextCompany.extensions.isUndefOrNull(
                                this.state
                                  .jobOfferPackAdvantagesExtended,
                              ) &&
                              this.state
                                .jobOfferPackAdvantagesExtended.length
                                ? this.state.jobOfferPackAdvantagesExtended.map(
                                    (jobOfferPackAdvantages) => (
                                      <li
                                        key={
                                          jobOfferPackAdvantages.advantage_code
                                        }
                                      >
                                        {
                                          jobOfferPackAdvantages.advantage_defaultname
                                        }
                                      </li>
                                    ),
                                  )
                                : null}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-group'>
                  <div className='card bg-transparent'>
                    <div className='row'>
                      {/* <div className='col-4'>
                        <Player
                          playsInline
                          poster='/assets/poster.png'
                          src={
                            !myNextCompany.extensions.isUndefOrNull(
                              this.state.jobOffer
                                .joboffer_video_media_id,
                            )
                              ? this.state.jobOffer.joboffer_video_url
                              : ''
                          }
                        />
                        <div
                          className='c-pointer'
                          onClick={this.uploadVideo}
                        >
                          <input
                            type='file'
                            id='upload-video'
                            className='d-none'
                            onChange={this.fileChangedHandlerVideo}
                          />
                          <span
                            className='badge badge-pill badge-success position-absolute badge-top-right'
                            style={{ right: '3px' }}
                          >
                            <i className='fas fa-fw fa-upload'></i>
                          </span>
                        </div>

                        {!myNextCompany.extensions.isUndefOrNull(
                          this.state.jobOffer.joboffer_video_media_id,
                        ) ? (
                          <div
                            className='c-pointer'
                            onClick={() => {
                              this.jobOfferDeleteVideo(
                                this.state.jobOffer,
                              );
                            }}
                          >
                            <span
                              className='badge badge-pill badge-danger position-absolute badge-top-right'
                              style={{ right: '3px', top: '30px' }}
                            >
                              <i className='fas fa-fw fa-trash'></i>
                            </span>
                          </div>
                        ) : null}
                      </div> */}
                      <div className='col'>
                        <div className='row'>
                          <div className='card-body'>
                            <div className='heading'>
                              <h2>Job Offer picture</h2>
                              <div className='row'>
                                <div className='col-3'>
                                  <input
                                    type='file'
                                    id='upload-picture'
                                    className='d-none'
                                    multiple='multiple'
                                    onChange={
                                      this.filePictureChangedHandler
                                    }
                                  />
                                  <button
                                    className='btn-success btn-circle w-150px h-150px border-0 rounded-circle m-3'
                                    onClick={this.uploadPicture}
                                  >
                                    <i className='fa fa-upload fa-6x'></i>
                                  </button>
                                </div>

                                {!myNextCompany.extensions.isUndefOrNull(
                                  this.state.jobOfferPicturesExtended,
                                ) &&
                                this.state.jobOfferPicturesExtended
                                  .length
                                  ? this.state.jobOfferPicturesExtended.map(
                                      (jobOfferPicture) => (
                                        // <img src={myNextCompany.extensions.urlImagesCompanyPictures(userCompanyPicture.picture_url)} className="w-150px h-150px rounded-circle m-3" key={userCompanyPicture.id} />
                                        <div
                                          className='col-3'
                                          key={jobOfferPicture.id}
                                        >
                                          {/* <img src={myNextCompany.extensions.urlImagesCompanyPictures(userCompanyPicture.picture_url)} alt="" className="w-150px h-150px list-thumbnail border-0 rounded-circle m-3" /> */}
                                          <img
                                            src={
                                              jobOfferPicture.media_url
                                            }
                                            alt=''
                                            className='w-150px h-150px list-thumbnail border-0 rounded-circle m-3'
                                          />
                                          <div
                                            className='c-pointer'
                                            onClick={() => {
                                              this.jobOfferDeletePicture(
                                                jobOfferPicture,
                                              );
                                            }}
                                          >
                                            <span className='badge badge-pill badge-danger position-absolute badge-top-left'>
                                              <i className='fas fa-fw fa-trash'></i>
                                            </span>
                                          </div>
                                        </div>
                                      ),
                                    )
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-group border-group'>
                  <div className='row'>
                    {/* Candidate Positions - Skills */}
                    <div className='col-3'>
                      <div className='row'>
                        <div className='col-6'>
                          <div>
                            <h2>Positions</h2>
                          </div>
                        </div>
                        {/* <div className="col-6 ">
                          <div>
                            <h2>Skills</h2></div>
                        </div> */}
                      </div>
                      {!myNextCompany.extensions.isUndefOrNull(
                        this.state.jobOfferPositionsExtended,
                      ) && this.state.jobOfferPositionsExtended.length
                        ? this.state.jobOfferPositionsExtended.map(
                            (jobOfferPositions) => (
                              <div
                                className='row mb-4'
                                key={jobOfferPositions.position_code}
                              >
                                {/* Candidate Positions */}
                                <div className='col checkbox-group'>
                                  <div className='range-group'>
                                    <div className='col-6'>
                                      <label>
                                        <span>
                                          {
                                            jobOfferPositions.position_defaultname
                                          }
                                        </span>
                                      </label>
                                    </div>
                                    <div className='col-6'>
                                      <input
                                        type='range'
                                        name='range'
                                        step='1'
                                        min='1'
                                        max='5'
                                        defaultValue={
                                          jobOfferPositions.weight
                                        }
                                        disabled='disabled'
                                      />
                                    </div>
                                  </div>
                                </div>

                                {/* Candidate Skills by position code */}
                                {/* <div className="col-6 checkbox-group">
                              {!myNextCompany.extensions.isUndefOrNull(this.state.candidateSkills)
                                && this.state.candidateSkills.length
                                // && this.state.candidateSkills.some(candidateSkill => candidateSkill.position_code === candidatePosition.position_code) //check skills exists by position code
                                ? this.state.candidateSkills.map(candidateSkill =>
                                  // candidateSkill.position_code === candidatePosition.position_code //render skills exists by postion code
                                  //   ?
                                    <div className="range-group" key={candidateSkill.id}>
                                      <div className="col-6">
                                        <label>
                                          <span>{candidateSkill.skill_defaultname}</span>
                                        </label>
                                      </div>
                                      <div className="col-6">
                                        <input type="range" name="range" step="1" min="1" max="5" defaultValue={candidateSkill.weight} disabled="disabled" />
                                      </div>
                                    </div>
                                    // : null
                                ) : null}
                            </div> */}
                              </div>
                            ),
                          )
                        : null}
                    </div>

                    {/* Candidate Languages */}
                    <div className='col-3'>
                      <div className='row'>
                        <div className='col'>
                          <div>
                            <h2>Skills</h2>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col checkbox-group'>
                          {!myNextCompany.extensions.isUndefOrNull(
                            this.state.jobOfferSkillsExtended,
                          ) &&
                          this.state.jobOfferSkillsExtended.length
                            ? this.state.jobOfferSkillsExtended.map(
                                (jobOfferSkills) => (
                                  <div
                                    className='range-group'
                                    key={jobOfferSkills.skill_code}
                                  >
                                    <div className='col-6'>
                                      <label>
                                        <span>
                                          {
                                            jobOfferSkills.skill_defaultname
                                          }
                                        </span>
                                      </label>
                                    </div>
                                    <div className='col-6'>
                                      <input
                                        type='range'
                                        name='range'
                                        step='1'
                                        min='1'
                                        max='5'
                                        defaultValue={
                                          jobOfferSkills.weight
                                        }
                                        disabled='disabled'
                                      />
                                    </div>
                                  </div>
                                ),
                              )
                            : null}
                        </div>
                      </div>
                    </div>

                    {/* Candidate Languages */}
                    <div className='col-3'>
                      <div className='row'>
                        <div className='col'>
                          <div>
                            <h2>Languages</h2>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col checkbox-group'>
                          {!myNextCompany.extensions.isUndefOrNull(
                            this.state.jobOfferLanguagesExtended,
                          ) &&
                          this.state.jobOfferLanguagesExtended.length
                            ? this.state.jobOfferLanguagesExtended.map(
                                (jobOfferLanguages) => (
                                  <div
                                    className='range-group'
                                    key={
                                      jobOfferLanguages.language_code
                                    }
                                  >
                                    <div className='col-6'>
                                      <label>
                                        <span>
                                          {
                                            jobOfferLanguages.language_defaultname
                                          }
                                        </span>
                                      </label>
                                    </div>
                                    <div className='col-6'>
                                      <input
                                        type='range'
                                        name='range'
                                        step='1'
                                        min='1'
                                        max='5'
                                        defaultValue={
                                          jobOfferLanguages.weight
                                        }
                                        disabled='disabled'
                                      />
                                    </div>
                                  </div>
                                ),
                              )
                            : null}
                        </div>
                      </div>
                    </div>

                    {/* Candidate Contract */}
                    <div className='col-3'>
                      <h2>Contract</h2>
                      <p>{this.state.contractInfo.contractsLabel}</p>
                      <div>
                        <h2>Gross salary</h2>
                        {!myNextCompany.extensions.isUndefOrNull(
                          this.state.contractInfo.employee,
                        ) &&
                        !myNextCompany.extensions.isUndefOrNull(
                          this.state.jobOffer.monthlysalary,
                        ) &&
                        this.state.jobOffer.monthlysalary.length ? (
                          <p>
                            {this.state.jobOffer.monthlysalary +
                              ' monthly'}
                          </p>
                        ) : null}

                        {!myNextCompany.extensions.isUndefOrNull(
                          this.state.contractInfo.freeblance,
                        ) &&
                        !myNextCompany.extensions.isUndefOrNull(
                          this.state.jobOffer.dailyrate,
                        ) &&
                        this.state.jobOffer.dailyrate.length ? (
                          <p>
                            {this.state.jobOffer.dailyrate + ' daily'}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <NoDataFoundPage />
          )
        ) : null}
      </div>
    );
  }
}
