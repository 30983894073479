import * as config from '_utilities/config';
import axiosService from './axiosService';

export const API = {
  login: async (endpoint, username, password) => {
    axiosService.setHeader('Content-Type', 'application/x-www-form-urlencoded');
    let data = 'grant_type=password&username=' + username + '&password=' + password;
    let response = await axiosService.login(`${config.API_URL}/${endpoint}`, data);
    return response;
  },
  get: async endpoint => {
    let response = await axiosService.get(`${config.API_URL}/${endpoint}`);
    return response;
  },
  post: async (endpoint, data, headers) => {
    let response = await axiosService.post(`${config.API_URL}/${endpoint}`, data);
    return response;
  },
  put: async (endpoint, data) => {
    let response = await axiosService.put(`${config.API_URL}/${endpoint}`, data);
    return response;
  },
  delete: async (endpoint, data) => {
    let response = await axiosService.delete(`${config.API_URL}/${endpoint}`, data);
    return response;
  }
};
