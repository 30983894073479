import Routes from 'components/Routes';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { history, store } from '_helpers';

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </Router>
    );
  }
}
export default App;
