import { API } from '_utilities/API'

export const jobTitlesService = {
    InitJobTitles: async () => {
        return await API.get("api/jobtitles/init");
    },
    FilterJobTitles: async data => {
        return await API.post("api/jobtitles/filter", data);
    },
    AddJobTitle: async data => {
        return await API.post("api/jobtitles/add", data);
    },
    UpdateJobTitle: async data => {
        return await API.put("api/jobtitles/update", data);
    },
    DeleteJobTitle: async data => {
        return await API.delete("api/jobtitles/delete", data);
    },
    RestoreJobTitle: async data => {
        return await API.post("api/jobtitles/restore", data);
    },
}

