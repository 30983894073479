import React from 'react';

export const LoaderButton = ({ isLoading, text, loadingText, className = '', disabled = false, ...props }) => {
  return (
    <button className={`LoaderButton ${className}`} disabled={disabled || isLoading} {...props}>
      <span>
        <i className='material-icons secure-icon'>lock</i>
        <span style={{ clear: 'both', }}>{isLoading ? loadingText : text}</span>
      </span>
      {isLoading && <span className='loading-login loading' />}
    </button>
  );
};
