import { API } from '_utilities/API'

export const areasOfExpertiseService = {
    InitAreasOfExpertise: async () => {
        return await API.get("api/areasofexpertise/init");
    },
    FilterAreasOfExpertise: async data => {
        return await API.post("api/areasofexpertise/filter", data);
    },
    AddAreasOfExperty: async data => {
        return await API.post("api/areasofexpertise/add", data);
    },
    UpdateAreasOfExperty: async data => {
        return await API.put("api/areasofexpertise/update", data);
    },
    DeleteAreasOfExperty: async data => {
        return await API.delete("api/areasofexpertise/delete", data);
    },
    RestoreAreasOfExperty: async data => {
        return await API.post("api/areasofexpertise/restore", data);
    },
}

