import React, { Component } from 'react';
import Select from 'react-select';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { myNextCompany } from '_utilities/common';

export default class UserCompaniesFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCompaniesFilter: {
        zipcode: '',
        registrationDateFrom: null,
        registrationDateTo: null,
      },
      isarchived: false,
      activityAreasSelected: [],
      countriesSelected: [],
    };
  }

  onChange = e => {
    var userCompaniesFilter = this.state.userCompaniesFilter;
    userCompaniesFilter[e.target.name] = e.target.value;

    this.setState({
      userCompaniesFilter: userCompaniesFilter,
    });
  };

  onSubmit = e => {
    e.preventDefault();
    let dataFilter = {
      ...this.state.userCompaniesFilter,
      activityAreas:
        !myNextCompany.extensions.isUndefOrNull(
          this.state.activityAreasSelected,
        ) && this.state.activityAreasSelected.length
          ? this.state.activityAreasSelected.map(
              language => language.value,
            )
          : null,
      countries:
        !myNextCompany.extensions.isUndefOrNull(
          this.state.countriesSelected,
        ) && this.state.countriesSelected.length
          ? this.state.countriesSelected.map(country => country.value)
          : null,
      zipcode:
        !myNextCompany.extensions.isUndefOrNull(
          this.state.userCompaniesFilter.zipcode,
        ) && this.state.userCompaniesFilter.zipcode.length
          ? this.state.userCompaniesFilter.zipcode
              .replace(/ /g, '')
              .split(',')
          : null,
      isarchived: this.state.isarchived,
    };
    this.props.filterUserCompanies(dataFilter);
  };

  render() {
    var activityAreaOptions = this.props.activityAreas.map(
      language => ({
        value: language.code,
        label: language.defaultname,
      }),
    );

    var countryOptions = this.props.countries.map(country => ({
      value: country.isocode,
      label: country.name,
    }));

    return (
      <div className='filters'>
        <form onSubmit={this.onSubmit}>
          <div className='row'>
            <div className='col-xl-11'>
              <div className='form-row align-items-center mb-2'>
                <DateRangePicker
                  format='yyyy/MM/dd'
                  className={['w-265px', 'mr-2', 'ml-1']}
                  onChange={dateRange =>
                    this.setState({
                      userCompaniesFilter: {
                        ...this.state.userCompaniesFilter,
                        registrationDateFrom:
                          dateRange != null ? dateRange[0] : null,
                        registrationDateTo:
                          dateRange != null ? dateRange[1] : null,
                      },
                    })
                  }
                  value={[
                    this.state.userCompaniesFilter
                      .registrationDateFrom,
                    this.state.userCompaniesFilter.registrationDateTo,
                  ]}
                />

                <Select
                  isMulti={true}
                  className='w-200px mr-2'
                  placeholder='Activity Area'
                  value={this.state.activityAreasSelected}
                  onChange={activityAreasSelected => {
                    this.setState({ activityAreasSelected });
                  }}
                  options={activityAreaOptions}
                />

                <div className='w-200px mr-2'>
                  <input
                    type='text'
                    name='zipcode'
                    className='form-control'
                    placeholder='Post Code'
                    value={this.state.userCompaniesFilter.zipcode}
                    onChange={this.onChange}
                  />
                </div>

                <Select
                  isMulti={true}
                  className='w-200px mr-2'
                  placeholder='Country'
                  value={this.state.countriesSelected}
                  onChange={countriesSelected => {
                    this.setState({ countriesSelected });
                  }}
                  options={countryOptions}
                />

                <div className='ml-5'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={this.state.isarchived}
                    onChange={() => {
                      this.setState({
                        isarchived: !this.state.isarchived,
                      });
                    }}
                  />
                  <label className='form-check-label'>Archived</label>
                </div>
              </div>
            </div>
            <div className='col-xl-1 col-md-6 text-right mt-1'>
              <button type='submit' className='btn btn-primary'>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
