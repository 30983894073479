import { API } from '_utilities/API'

export const standardMessagesService = {
    InitStandardMessages: async () => {
        return await API.get("api/standardmessages/init");
    },
    FilterStandardMessages: async data => {
        return await API.post("api/standardmessages/filter", data);
    },
    AddStandardMessage: async data => {
        return await API.post("api/standardmessages/add", data);
    },
    UpdateStandardMessage: async data => {
        return await API.put("api/standardmessages/update", data);
    },
    DeleteStandardMessage: async data => {
        return await API.delete("api/standardmessages/delete", data);
    },
    RestoreStandardMessage: async data => {
        return await API.post("api/standardmessages/restore", data);
    },
}

