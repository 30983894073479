import { API } from '_utilities/API'

export const pricingPlansService = {
    InitPricingPlans: async () => {
        return await API.get("api/PricingPlans/init");
    },
    FilterPricingPlans: async data => {
        return await API.post("api/PricingPlans/filter", data);
    },
    AddPricingPlans: async data => {
        return await API.post("api/PricingPlans/add", data);
    },
    UpdatePricingPlans: async data => {
        return await API.put("api/PricingPlans/update", data);
    },
}

