import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { ChildMenuLink } from './ChildMenuLink'
import { MenuLink } from './MenuLink'

class SideBar extends Component {
    render() {
        return (
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                {/*- Sidebar - Brand */}
                <Link to="/" className="sidebar-brand d-flex align-items-center justify-content-center">
                    <img src="/img/logo.svg" alt="" />
                </Link>

                <hr className="sidebar-divider my-0" />
                <MenuLink to="/" activeOnlyWhenExact={true}>
                    <Link className="nav-link" to="/">
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span>Dashboard</span>
                    </Link>
                </MenuLink>
                <hr className="sidebar-divider" />
                <div className="sidebar-heading">
                    Management
                </div>
                <MenuLink to="/candidates" >
                    <Link className="nav-link" to="/candidates"><i className="fas fa-users"></i>
                        <span>Candidates</span></Link>
                </MenuLink>
                <MenuLink to="/companies" >
                    <Link className="nav-link" to="/companies"><i className="fas fa-building"></i>
                        <span>Companies</span></Link>
                </MenuLink>
                <MenuLink to="/joboffers" >
                    <NavLink className="nav-link" to="/joboffers"><i className="fas fa-tasks"></i>
                        <span>Job Offers</span></NavLink>
                </MenuLink>

                <hr className="sidebar-divider" />

                <div className="sidebar-heading">
                    Interface
                </div>
                <MenuLink to="/tables">
                    <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapseTables" aria-expanded="true" aria-controls="collapseTwo">
                        <i className="fas fa-fw fa-table"></i>
                        <span>Tables</span>
                    </a>
                    <ChildMenuLink id="collapseTables" to="/tables" activeOnlyWhenExact={false}>
                        <NavLink to="/tables/activityareas" className="collapse-item" activeClassName="active">Activity Areas</NavLink>
                        <NavLink to="/tables/aeasofexpertise" className="collapse-item" activeClassName="active">Areas Of Expertise</NavLink>
                        <NavLink to="/tables/areasofexpertise-positions-relation" className="collapse-item" activeClassName="active">AoE Positions Relation</NavLink>
                        <NavLink to="/tables/areasofexpertise-positions-skills-relation" className="collapse-item" activeClassName="active">AoE Positions Skills Relation</NavLink>
                        <NavLink to="/tables/countries" className="collapse-item" activeClassName="active">Countries</NavLink>
                        <NavLink to="/tables/companies" className="collapse-item" activeClassName="active">Companies</NavLink>
                        {/* <NavLink to="/tables/jobtitles" className="collapse-item" activeClassName="active">Job Titles</NavLink> */}
                        <NavLink to="/tables/languages" className="collapse-item" activeClassName="active">Languages</NavLink>
                        <NavLink to="/tables/languagesbycountry" className="collapse-item" activeClassName="active">Languages by country</NavLink>
                        <NavLink to="/tables/packadvantages" className="collapse-item" activeClassName="active">Pack Advantages</NavLink>
                        <NavLink to="/tables/positions" className="collapse-item" activeClassName="active">Positions</NavLink>
                        <NavLink to="/tables/skills" className="collapse-item" activeClassName="active">Skills</NavLink>
                        <NavLink to="/tables/standardmessages" className="collapse-item" activeClassName="active">Standard Messages</NavLink>
                        <NavLink to="/tables/companyemailrestricted" className="collapse-item" activeClassName="active">Company Email Restricted</NavLink>
                        <NavLink to="/tables/pricingPlans" className="collapse-item" activeClassName="active">Pricing Plans</NavLink>
                        <NavLink to="/tables/promoCodes" className="collapse-item" activeClassName="active">Promo Codes</NavLink>
                    </ChildMenuLink>
                </MenuLink>

                {/*- Divider */}
                <hr className="sidebar-divider d-none d-md-block" />

                {/*- Sidebar Toggler (Sidebar) */}
                <div className="text-center d-none d-md-inline">
                    <button className="rounded-circle border-0" id="sidebarToggle"></button>
                </div>
            </ul>

        )
    }
}
export default SideBar;