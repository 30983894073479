import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminLayoutRoute from 'components/AdminLayoutRoute';
import ActivityAreas from 'pages/tables/activityAreas/ActivityAreas';
import AreasOfExpertise from 'pages/tables/AreasOfExpertise';
import AreasOfExpertisePositionsRelation from 'pages/tables/AreasOfExpertisePositionsRelation';
import AreasOfExpertisePositionsSkillsRelation from 'pages/tables/AreasOfExpertisePositionsSkillsRelation';
import Countries from 'pages/tables/Countries';
import Languages from 'pages/tables/Languages';
import LanguagesByCountry from 'pages/tables/LanguagesByCountry';
import PackAdvantages from 'pages/tables/PackAdvantages';
import Positions from 'pages/tables/Positions';
import Skills from 'pages/tables/Skills';
import CompanyEmailRestricted from 'pages/tables/CompanyEmailRestricted';
import PricingPlans from 'pages/tables/PricingPlans';
import PromoCodes from 'pages/tables/PromoCodes';
import StandardMessages from 'pages/tables/StandardMessages';
import tbCompanies from 'pages/tables/Companies';
import JobOffers from 'pages/jobOffers/JobOffers';
import JobOfferDetail from 'pages/jobOffers/JobOfferDetail';
import UserCompanies from 'pages/userCompanies/UserCompanies';
import UserCompanyDetail from 'pages/userCompanies/UserCompanyDetail';
import Candidates from 'pages/candidates/Candidates';
import CandidateDetail from 'pages/candidates/CandidateDetail';
import NotFound from 'pages/NotFound';
import DashBoard from 'pages/Dashboard';
import Login from 'pages/Login';
class Routes extends Component {
  render() {
    return (
      <Switch>
        <AdminLayoutRoute path='/' exact component={DashBoard} />
        <AdminLayoutRoute
          path='/tables/activityareas'
          component={ActivityAreas}
        />
        <AdminLayoutRoute
          path='/tables/aeasofexpertise'
          component={AreasOfExpertise}
        />
        <AdminLayoutRoute
          path='/tables/areasofexpertise-positions-relation'
          component={AreasOfExpertisePositionsRelation}
        />
        <AdminLayoutRoute
          path='/tables/areasofexpertise-positions-skills-relation'
          component={AreasOfExpertisePositionsSkillsRelation}
        />
        <AdminLayoutRoute path='/tables/countries' component={Countries} />
        <AdminLayoutRoute path='/tables/companies' component={tbCompanies} />      
        <AdminLayoutRoute path='/tables/languages' component={Languages} />
        <AdminLayoutRoute path='/tables/languagesbycountry' component={LanguagesByCountry} />
        <AdminLayoutRoute
          path='/tables/packadvantages'
          component={PackAdvantages}
        />
        <AdminLayoutRoute path='/tables/positions' component={Positions} />
        <AdminLayoutRoute path='/tables/skills' component={Skills} />
        <AdminLayoutRoute
          path='/tables/standardmessages'
          component={StandardMessages}
        />
        <AdminLayoutRoute
          path='/candidates/detail/:candidateId?'
          component={CandidateDetail}
        />
        <AdminLayoutRoute path='/candidates' component={Candidates} />
        <AdminLayoutRoute
          path='/companies/detail/:companyId'
          component={UserCompanyDetail}
        />
        <AdminLayoutRoute path='/companies' component={UserCompanies} />
        <AdminLayoutRoute path='/jobOffers/detail/:jobOfferId' component={JobOfferDetail} />
        <AdminLayoutRoute path='/jobOffers' component={JobOffers} />
        <AdminLayoutRoute path='/tables/companyEmailRestricted' component={CompanyEmailRestricted} />
        <AdminLayoutRoute path='/tables/pricingPlans' component={PricingPlans} />
        <AdminLayoutRoute path='/tables/promoCodes' component={PromoCodes} />
        <Route path='/login' component={Login} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default Routes;
