import { API } from '_utilities/API'

export const candidatesService = {
    InitCandidates: async () => {
        return await API.get("api/candidates/init");
    },
    FilterCandidates: async data => {
        return await API.post("api/candidates/filter", data);
    },
    CandidateDetail: async candidateId => {
        return await API.get("api/candidates/detail/?candidateId=" + candidateId);
    },
    CandidateUploadPicture: async data => {
        return await API.post("api/candidates/candidateuploadpicture", data);
    },
    CandidateDeletePicture: async data => {
        return await API.delete("api/candidates/candidatedeletepicture", data);
    },
    CandidateUploadVideo: async data => {
        return await API.post("api/candidates/candidateuploadvideo", data);
    },
    CandidateDeleteVideo: async data => {
        return await API.delete("api/candidates/candidatedeletevideo", data);
    },
    UpdateCandidate: async data => {
        return await API.put("api/candidates/update", data);
    },
};

