import { API } from '_utilities/API';

export const activityAreasService = {
  InitActivityAreas: async () => {
    return await API.get("api/activityareas/init");
  },
  FilterActivityAreas: async data => {
    return await API.post("api/activityareas/filter", data);
  },
  DetailActivityAreas: async (code) => {
    return await API.get('api/activityareas/detail/?code=' + code);
  },
  AddActivityArea: async data => {
    return await API.post('api/activityareas/add', data);
  },
  UpdateActivityArea: async data => {
    return await API.put('api/activityareas/update', data);
  },
  DeleteActivityArea: async data => {
    return await API.delete('api/activityareas/delete', data);
  },
  RestoreActivityArea: async data => {
    return await API.post("api/activityareas/restore", data);
  },
};
