import { API } from '_utilities/API'

export const countriesService = {
    InitCountries: async () => {
        return await API.get("api/countries/init");
    },
    FilterCountries: async data => {
        return await API.post("api/countries/filter", data);
    },
    AddCountry: async data => {
        return await API.post("api/countries/add", data);
    },
    UpdateCountry: async data => {
        return await API.put("api/countries/update", data);
    },
    DeleteCountry: async data => {
        return await API.delete("api/countries/delete", data);
    },
    RestoreCountry: async data => {
        return await API.post("api/countries/restore", data);
    },
}

