import React, { Component } from 'react';
import { myNextCompany } from '_utilities/common';
import { userCompaniesService, companyPictures } from '_services';
import * as config from '_utilities/config';

export default class UserCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userCompany: this.props.userCompany,
      userCompanyPictures: this.props.userCompanyPictures,
    };
  }

  fileLogoChangedHandler = event => {
    const data = new FormData();
    data.append('file', event.target.files[0]);
    data.append('UserId', this.state.userCompany.user_id);
    data.append('CompanyId', this.state.userCompany.company_id);
    userCompaniesService.UserCompanyUploadLogo(data).then(res => {
      if (!myNextCompany.extensions.isUndefOrNull(res.UserCompany)) {
        this.setState({
          userCompany: res.UserCompany,
        });
      }
    });
    event.target.value = '';
  };

  uploadLogo = () => {
    document.getElementById('upload-logo').click();
  };

  filePictureChangedHandler = event => {
    if (
      !myNextCompany.extensions.isUndefOrNull(event.target.files) &&
      event.target.files.length
    ) {
      for (
        let index = 0;
        index < event.target.files.length;
        index++
      ) {
        const file = event.target.files[index];
        const data = new FormData();
        data.append('file', file);
        data.append('UserId', this.state.userCompany.user_id);
        data.append('CompanyId', this.state.userCompany.company_id);
        userCompaniesService
          .UserCompanyUploadPicture(data)
          .then(res => {
            if (
              !myNextCompany.extensions.isUndefOrNull(
                res.UserCompanyPictures,
              )
            ) {
              this.setState({
                userCompanyPictures: res.UserCompanyPictures,
              });
            }
          });
      }
    }
    event.target.value = '';
  };

  uploadPicture = () => {
    document.getElementById('upload-picture').click();
  };

  deleteUserCompanyPicture(userCompanyPicture) {
    companyPictures
      .DeleteCompanyPicture(userCompanyPicture)
      .then(res => {
        if (
          res.ResponseStatusCode ===
            config.ResponseStatusCodes.Success &&
          res.IsDeleted
        ) {
          let userCompanyPictures = this.state.userCompanyPictures;
          const index = userCompanyPictures.indexOf(
            userCompanyPicture,
          );
          userCompanyPictures.splice(index, 1);
          this.setState({ userCompanyPictures });
        }
      });
  }

  userCompanyDeleteLogo(userCompany) {
    var data = {
      CompanyId: userCompany.company_id,
      MediaId: userCompany.picture_media_id,
    };
    userCompaniesService.UserCompanyDeleteLogo(data).then(res => {
      if (
        res.ResponseStatusCode ===
          config.ResponseStatusCodes.Success &&
        res.IsDeleted
      ) {
        if (
          !myNextCompany.extensions.isUndefOrNull(res.UserCompany)
        ) {
          this.setState({
            userCompany: res.UserCompany,
          });
        }
      }
    });
  }
  render() {
    return (
      <div>
        <div className='content-box'>
          <div className='form-group'>
            <div className='card bg-transparent border-1'>
              <div className='row'>
                <div className='col-2'>
                  <div
                    className='c-pointer'
                    onClick={this.uploadLogo}
                  >
                    <input
                      type='file'
                      id='upload-logo'
                      className='d-none'
                      onChange={this.fileLogoChangedHandler}
                    />
                    <span
                      className='badge badge-pill badge-success position-absolute badge-top-left'
                      style={{ left: '35px' }}
                    >
                      <i className='fas fa-fw fa-upload'></i>
                    </span>
                  </div>

                  {!myNextCompany.extensions.isUndefOrNull(
                    this.state.userCompany.picture_media_id,
                  ) ? (
                    <div>
                      <img
                        src={this.state.userCompany.picture_url}
                        className='img-candidate card-img rounded-circle m-3'
                        alt='...'
                      />
                      <div
                        className='c-pointer'
                        onClick={() => {
                          this.userCompanyDeleteLogo(
                            this.state.userCompany,
                          );
                        }}
                      >
                        <span
                          className='badge badge-pill badge-danger position-absolute badge-top-left'
                          style={{ top: '50px', left: '25px' }}
                        >
                          <i className='fas fa-fw fa-trash'></i>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <img
                      src={myNextCompany.values.uploadedLogo}
                      className='img-candidate card-img rounded-circle m-3'
                      alt='...'
                    />
                  )}
                </div>
                <div className='card-body'>
                  <div className='heading'>
                    <h2>{this.state.userCompany.companyname}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-md-6 mb-3'>
              <h2>Company name</h2>
              <p>{this.state.userCompany.companyname}</p>
            </div>
            <div className='col-md-6 mb-3'>
              <h2>Activity area</h2>
              <p>{this.state.userCompany.activityarea_defaultname}</p>
            </div>
          </div>

          <div className='form-row'>
            <div className='col-md-6 mb-3'>
              <h2>Country</h2>
              <p>
                Address: {this.state.userCompany.streetnumber},{' '}
                {this.state.userCompany.street},{' '}
                {this.state.userCompany.city}
              </p>
              <p>VAT Number: {this.state.userCompany.vatnumber}</p>
            </div>
            <div className='col-md-6 mb-3'>
              <h2>Postcode</h2>
              <p>{this.state.userCompany.zipcode}</p>
            </div>
          </div>

          <div>
            <h2>Company picture</h2>
          </div>
          <div className='row'>
            <div className='col-2'>
              <input
                type='file'
                id='upload-picture'
                className='d-none'
                multiple='multiple'
                onChange={this.filePictureChangedHandler}
              />
              <button
                className='btn-success btn-circle w-150px h-150px border-0 rounded-circle m-3'
                onClick={this.uploadPicture}
              >
                <i className='fa fa-upload fa-6x'></i>
              </button>
            </div>

            {!myNextCompany.extensions.isUndefOrNull(
              this.state.userCompanyPictures,
            ) && this.state.userCompanyPictures.length
              ? this.state.userCompanyPictures.map(
                  userCompanyPicture => (
                    // <img src={myNextCompany.extensions.urlImagesCompanyPictures(userCompanyPicture.picture_url)} className="w-150px h-150px rounded-circle m-3" key={userCompanyPicture.id} />
                    <div
                      className='col-2'
                      key={userCompanyPicture.id}
                    >
                      {/* <img src={myNextCompany.extensions.urlImagesCompanyPictures(userCompanyPicture.picture_url)} alt="" className="w-150px h-150px list-thumbnail border-0 rounded-circle m-3" /> */}
                      <img
                        src={userCompanyPicture.picture_url}
                        alt=''
                        className='w-150px h-150px list-thumbnail border-0 rounded-circle m-3'
                      />
                      <div
                        className='c-pointer'
                        onClick={() => {
                          this.deleteUserCompanyPicture(
                            userCompanyPicture,
                          );
                        }}
                      >
                        <span className='badge badge-pill badge-danger position-absolute badge-top-left'>
                          <i className='fas fa-fw fa-trash'></i>
                        </span>
                      </div>
                    </div>
                  ),
                )
              : null}
          </div>
        </div>
      </div>
    );
  }
}
