import { API } from '_utilities/API'

export const areasOfExpertisePositionsRelationService = {
    InitAreasOfExpertisePositionsRelation: async () => {
        return await API.get("api/areasofexpertisepositionsrelation/init");
    },
    FilterAreasOfExpertisePositionsRelation: async data => {
        return await API.post("api/areasofexpertisepositionsrelation/filter", data);
    },
    AddAreasOfExpertisePositionsRelationItem: async data => {
        return await API.post("api/areasofexpertisepositionsrelation/add", data);
    },
    UpdateAreasOfExpertisePositionsRelationItem: async data => {
        return await API.put("api/areasofexpertisepositionsrelation/update", data);
    },
    DeleteAreasOfExpertisePositionsRelationItem: async data => {
        return await API.delete("api/areasofexpertisepositionsrelation/delete", data);
    },
    RestoreAreasOfExpertisePositionsRelationItem: async data => {
        return await API.post("api/areasofexpertisepositionsrelation/restore", data);
    },
}

