import React, { Component } from 'react';
import './style.css';
export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
  }
  onChange = () => {
    this.setState({ value: !this.state.value });
    this.props.onChange();
  };
  render() {
    return (
      <label className='switch'>
        <input
          type='checkbox'
          defaultChecked={this.state.value}
          onClick={this.onChange}
        />
        <span className='slider round'></span>
      </label>
    );
  }
}
