import React, { Component } from 'react';
import UserCompanyProfile from './UserCompanyProfile';
import UserCompany from './UserCompany';
import UserCompanyJobOffers from './UserCompanyJobOffers';
import UserCompanySubscription from './UserCompanySubscription';
import { userCompaniesService } from '_services';
import LoadingBar from 'react-top-loading-bar';
import NoDataFoundPage from 'pages/NoDataFound';
import { myNextCompany } from '_utilities/common';

export default class UserCompanyDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      userCompany: null,
      userCompanyPictures: [],
      userCompanyJobOffers: [],
      userCompanySubscription: null,
    };
  }

  componentDidMount() {
    this.LoadingBar.continuousStart();
    let companyId = this.props.match.params.companyId;
    if (!myNextCompany.extensions.isUndefOrNull(companyId)) {
      userCompaniesService
        .UserCompanyDetail(companyId)
        .then((data) => {
          this.setState({
            userCompany: data.UserCompany,
            userCompanyPictures: data.UserCompanyPictures,
            userCompanyJobOffers: data.UserCompanyJobOffers,
            userCompanySubscription: data.UserCompanySubscription,
            isLoading: false,
          });
          this.LoadingBar.complete();
        });
    }
  }

  render() {
    return (
      <div>
        <LoadingBar
          height={3}
          color='#f11946'
          onRef={(ref) => (this.LoadingBar = ref)}
        />

        <ul className='nav nav-tabs' id='myTab' role='tablist'>
          <li className='nav-item'>
            <a
              className='nav-link active'
              id='profile-tab'
              data-toggle='tab'
              href='#profile'
              role='tab'
              aria-controls='profile'
              aria-selected='false'
            >
              Profile
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              id='company-tab'
              data-toggle='tab'
              href='#company'
              role='tab'
              aria-controls='company'
              aria-selected='false'
            >
              Company
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              id='jobs-tab'
              data-toggle='tab'
              href='#jobs'
              role='tab'
              aria-controls='jobs'
              aria-selected='false'
            >
              Jobs
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              id='subscription-tab'
              data-toggle='tab'
              href='#subscription'
              role='tab'
              aria-controls='subscription'
              aria-selected='false'
            >
              Subscription
            </a>
          </li>
        </ul>
        {!this.state.isLoading ? (
          <div className='tab-content' id='myTabContent'>
            <div
              className='tab-pane fade show active'
              id='profile'
              role='tabpanel'
              aria-labelledby='profile-tab'
            >
              {!myNextCompany.extensions.isUndefOrNull(
                this.state.userCompany,
              ) ? (
                <UserCompanyProfile
                  userCompany={this.state.userCompany}
                />
              ) : (
                <NoDataFoundPage />
              )}
            </div>
            <div
              className='tab-pane fade'
              id='company'
              role='tabpanel'
              aria-labelledby='company-tab'
            >
              {!myNextCompany.extensions.isUndefOrNull(
                this.state.userCompany,
              ) ? (
                <UserCompany
                  userCompany={this.state.userCompany}
                  userCompanyPictures={this.state.userCompanyPictures}
                />
              ) : (
                <NoDataFoundPage />
              )}
            </div>
            <div
              className='tab-pane fade'
              id='jobs'
              role='tabpanel'
              aria-labelledby='jobs-tab'
            >
              {!myNextCompany.extensions.isUndefOrNull(
                this.state.userCompanyJobOffers,
              ) && this.state.userCompanyJobOffers.length ? (
                <UserCompanyJobOffers
                  userCompanyJobOffers={
                    this.state.userCompanyJobOffers
                  }
                />
              ) : (
                <NoDataFoundPage />
              )}
            </div>
            <div
              className='tab-pane fade'
              id='subscription'
              role='tabpanel'
              aria-labelledby='subscription-tab'
            >
              {!myNextCompany.extensions.isUndefOrNull(
                this.state.userCompany,
              ) ? (
                <UserCompanySubscription
                  companyId={this.state.userCompany.company_id}
                />
              ) : (
                <NoDataFoundPage />
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
