import { API } from '_utilities/API'

export const languagesService = {
    InitLanguages: async () => {
        return await API.get("api/languages/init");
    },
    FilterLanguages: async data => {
        return await API.post("api/languages/filter", data);
    },
    AddLanguage: async data => {
        return await API.post("api/languages/add", data);
    },
    UpdateLanguage: async data => {
        return await API.put("api/languages/update", data);
    },
    DeleteLanguage: async data => {
        return await API.delete("api/languages/delete", data);
    },
    RestoreLanguage: async data => {
        return await API.post("api/languages/restore", data);
    },
}

